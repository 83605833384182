import React, { ImgHTMLAttributes, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import defaultFallback from "../../../public/assets/images/preview-not-avaiable.jpg"

interface ImageWithFallbackProps extends ImgHTMLAttributes<HTMLImageElement> {
  fallback?: string
  src: string
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  fallback = defaultFallback.src,
  src,
  ...props
}: ImageWithFallbackProps) => {
  const { t } = useTranslation()
  const [imgSrc, setImgSrc] = useState<string>(src)
  const onError = () => setImgSrc(fallback)

  useEffect(() => {
    setImgSrc(src)

    return () => {
      setImgSrc("")
    }
  }, [src])

  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      {...props}
      src={imgSrc}
      onError={onError}
      alt={t("label:imageNotFound")}
    />
  )
}

export default ImageWithFallback
