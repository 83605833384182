import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react"
import { notification } from "antd"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { MAX_UPLOAD_FILE } from "../constants/app-config"
import { useUser } from "@auth0/nextjs-auth0"
import { useRouter } from "next/router"
import { useDrive } from "./use-drive"
import { INVALID_PARENT_ID } from "../constants/errors"
import { RcFile } from "antd/es/upload"
import { IFileUploadState } from "../interfaces/application-interfaces"
import { FileStreamingStatus } from "../constants/enums"
import loadImage from "blueimp-load-image"
import { UploadFileMonitor } from "../components/upload-file-monitor"
import { isFileNotUploadable } from "../utils"

interface UploaderContextType {
  fileList: IFileUploadState[]
  uploadFile: (file: File | RcFile) => Promise<void>
}

export const UploaderContext = createContext<UploaderContextType>({
  fileList: [],
  uploadFile: async (file: File | RcFile) => {},
})

export interface UploadProviderProps {
  children: ReactElement
}

let _fileList: IFileUploadState[] = []

export const UploaderProvider = ({ children }: UploadProviderProps) => {
  const { user } = useUser()
  const router = useRouter()
  const { createFile, createFolder } = useDrive()
  const { t } = useTranslation()
  const [fileList, setFileList] = useState<IFileUploadState[]>([])

  const [notificationKey, setNotificationKey] = useState<string>(
    `notification-key-${new Date().getTime()}`
  )

  const [api, contextHolder] = notification.useNotification()

  const handleFileProgress = ({ total, loaded, s3Key }: any) => {
    const fileState = _fileList.find(item => item.s3Key === s3Key)
    if (fileState) {
      fileState.progress = loaded / total
    }

    setFileList([..._fileList])
  }

  const handleFileUploaded = async (s3Key: string, parentId: string) => {
    const fileState = _fileList.find(item => item.s3Key === s3Key)
    if (fileState) {
      fileState.progress = 1
      fileState.status = FileStreamingStatus.UploadCompleted

      await createFile(parentId, fileState, s3Key)
    }
    setFileList([..._fileList])
  }

  const handleFileUploadError = (error: any, s3Key: string) => {
    const fileState = _fileList.find(item => item.s3Key === s3Key)
    if (fileState) {
      fileState.error = error
      fileState.status = FileStreamingStatus.Failed
    }

    setFileList([..._fileList])
  }

  const handleUploadFile = async (file: File | RcFile, folderId?: string) => {
    if (isFileNotUploadable(file.name)) {
      return
    }

    let _s3key = ""

    try {
      // get signed Url
      if (!folderId) {
        folderId = Array.isArray(router.query.folderId)
          ? router.query.folderId[0]
          : router.query.folderId
        if (!folderId) {
          throw new Error(INVALID_PARENT_ID)
        }
      }

      const response = await axios.post(
        `/api/v1/files/${folderId}/upload-url`,
        { folderId }
      )
      const { signedUrl, s3Key } = response.data
      _s3key = s3Key
      const cancelTokenSource = axios.CancelToken.source()

      const fileData: IFileUploadState = {
        file,
        s3Key,
        progress: 0,
        error: null,
        cancelTokenSource,
        status: FileStreamingStatus.Uploading,
      }

      if (/image\//.test(file.type)) {
        // @ts-ignore
        const imageData = await loadImage(file, { meta: true })
        // @ts-ignore
        if (imageData.exif) {
          // @ts-ignore
          fileData.exif = imageData.exif.getAll()
        }

        // @ts-ignore
        fileData.width = imageData.originalWidth
        // @ts-ignore
        fileData.height = imageData.originalHeight
      }

      _fileList = [..._fileList, fileData]

      setFileList(_fileList)

      const uploadResult = await axios.put(signedUrl, file, {
        headers: {
          "Content-Type": file.type,
          "Content-Disposition": `attachment; filename="${encodeURIComponent(
            file.name
          )}"`,
        },
        cancelToken: cancelTokenSource.token,
        onUploadProgress: ({ loaded, total }) => {
          handleFileProgress({ loaded, total, s3Key })
        },
      })

      await handleFileUploaded(s3Key, folderId)
    } catch (error) {
      if (_s3key) {
        handleFileUploadError(error, _s3key)
      }
    }
  }

  const uploadFolder = async (
    item: FileSystemEntry,
    parentFolderId: string,
    allUploadFilePromises?: Promise<any>[]
  ) => {
    let allPromises = allUploadFilePromises || []
    const uploadedFolderId = await createFolder(parentFolderId, item.name)
    // @ts-ignore
    const directoryReader = item.createReader()
    directoryReader.readEntries(async (entries: FileSystemEntry[]) => {
      entries.forEach(async (entry: FileSystemEntry) => {
        if (entry.isFile) {
          // @ts-ignore
          entry.file(async (file: File) => {
            allPromises.push(handleUploadFile(file, uploadedFolderId))
          })
        } else {
          await uploadFolder(entry, uploadedFolderId, allPromises)
        }
      })
    })

    return allPromises
  }

  const handleFileDrop = async (evt: DragEvent) => {
    evt.preventDefault()
    if (evt && evt.dataTransfer) {
      let maxFile = evt.dataTransfer.items.length

      if (MAX_UPLOAD_FILE > 0 && maxFile > MAX_UPLOAD_FILE) {
        notification.open({
          message: t("label:warning"),
          description: t("warning:maxUploadFileExceeded"),
        })

        maxFile = MAX_UPLOAD_FILE
      }

      let allUploadFilePromises: Promise<any>[] = []
      const parentFolderId = router.query.folderId as string

      for (let i = 0; i < maxFile; i++) {
        // If dropped items aren't files, reject them
        const item = evt.dataTransfer.items[i].webkitGetAsEntry()
        if (item) {
          if (item.isDirectory) {
            allUploadFilePromises = allUploadFilePromises.concat(
              uploadFolder(item, parentFolderId)
            )
          } else {
            const file = evt.dataTransfer.items[i].getAsFile()
            if (file) {
              allUploadFilePromises.push(handleUploadFile(file, parentFolderId))
            }
          }
        }
      }

      const result = await Promise.all(allUploadFilePromises)
    }
  }

  const handleDragOver = (evt: Event) => {
    evt.preventDefault()
  }

  useEffect(() => {
    if (!user) {
      return
    }

    window.document.addEventListener("drop", handleFileDrop)
    window.document.addEventListener("dragover", handleDragOver)
    return () => {
      window.document.removeEventListener("drop", handleFileDrop)
      window.document.removeEventListener("dragover", handleDragOver)
    }
  }, [user, router])

  useEffect(() => {
    const placement = "bottomRight"

    if (fileList.length === 0) {
      return
    }

    api.open({
      className: "file-upload-notification",
      message: t("label:uploadingFiles"),
      description: (
        <UploaderContext.Consumer>
          {({ fileList }) => {
            return (
              <UploadFileMonitor
                fileUploadStateList={fileList}
                onCancel={fileState => {
                  fileState.cancelTokenSource?.cancel()
                  const _fileState = _fileList.find(
                    ({ s3Key }) => s3Key === fileState.s3Key
                  )
                  if (_fileState) {
                    _fileState.status = FileStreamingStatus.Cancelled
                    setFileList([..._fileList])
                  }
                }}
                onRemove={fileState => {
                  _fileList = _fileList.filter(
                    ({ s3Key }) => s3Key !== fileState.s3Key
                  )
                  setFileList([..._fileList])
                }}
              />
            )
          }}
        </UploaderContext.Consumer>
      ),
      placement,
      duration: null,
      key: notificationKey,
    })
  }, [fileList])

  useEffect(() => {
    if (!notificationKey) {
      return
    }

    if (fileList.length === 0) {
      notification.close(notificationKey)
    }
  }, [fileList, notificationKey])

  return (
    <UploaderContext.Provider
      value={{ fileList, uploadFile: handleUploadFile }}
    >
      <>
        {contextHolder}
        {children}
      </>
    </UploaderContext.Provider>
  )
}

export const useUploader = () => useContext(UploaderContext)
