import React, {
  createContext,
  Dispatch,
  ReactElement,
  useContext,
  useReducer,
} from "react"

interface GlobalProviderProps {
  children: ReactElement
}

export interface GlobalReducerState {
  isReady: boolean
}

export interface GlobalReducerAction {
  type: string
  value: any
}

type GlobalContextType = [
  state: GlobalReducerState,
  dispatch: Dispatch<GlobalReducerAction>
]

const initialState: GlobalReducerState = {
  isReady: true,
}

export const GlobalContext = createContext<GlobalContextType>([
  initialState,
  () => {},
])

export const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const globalReducer = (
    state: GlobalReducerState,
    { type, value }: GlobalReducerAction
  ): GlobalReducerState => {
    switch (type) {
      default:
        return { ...state }
    }
  }

  const reducer = useReducer(globalReducer, initialState)

  return (
    <GlobalContext.Provider value={reducer}>{children}</GlobalContext.Provider>
  )
}

export const useGlobalValue = () => useContext(GlobalContext)
