import React from "react"
import Icon from "@ant-design/icons"
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon"

const MoveSvg: React.FC = () => (
  <svg width="1em" height="1em" viewBox="0 0 19.66 19.66">
    <path
      d="M12.019,0H3.072A3.075,3.075,0,0,0,0,3.072v8.947A3.075,3.075,0,0,0,3.072,15.09h8.947a3.075,3.075,0,0,0,3.072-3.072V3.072A3.075,3.075,0,0,0,12.019,0Zm1.536,12.019a1.538,1.538,0,0,1-1.536,1.536H3.072a1.538,1.538,0,0,1-1.536-1.536V3.072A1.538,1.538,0,0,1,3.072,1.536h8.947a1.538,1.538,0,0,1,1.536,1.536ZM11.98,7.411v2.3a2.306,2.306,0,0,1-2.3,2.3h-2.3a.768.768,0,0,1,0-1.536H9.358l-2.74-2.74A.768.768,0,0,1,7.7,6.657l2.74,2.74V7.411a.768.768,0,1,1,1.536,0Zm7.68,3.84a.768.768,0,1,1-.768-.768A.768.768,0,0,1,19.66,11.251ZM16.588,6.643a.768.768,0,0,1,.768-.768,2.306,2.306,0,0,1,2.3,2.3.768.768,0,0,1-1.536,0,.769.769,0,0,0-.768-.768A.768.768,0,0,1,16.588,6.643ZM11.942,18.892a.768.768,0,1,1-.768-.768A.768.768,0,0,1,11.942,18.892Zm-3.149,0a.768.768,0,0,1-.768.768,2.306,2.306,0,0,1-2.3-2.3.768.768,0,1,1,1.536,0,.769.769,0,0,0,.768.768A.768.768,0,0,1,8.793,18.891ZM19.66,14.207a.768.768,0,1,1-.768-.768A.768.768,0,0,1,19.66,14.207Zm-4.608,4.685a.768.768,0,1,1-.768-.768A.768.768,0,0,1,15.052,18.892Zm4.608-1.536a2.306,2.306,0,0,1-2.3,2.3.768.768,0,0,1,0-1.536.769.769,0,0,0,.768-.768.768.768,0,1,1,1.536,0Zm0,0"
      fill="#8c807c"
    />
  </svg>
)

const MoveIcon: React.FC<CustomIconComponentProps> = props => (
  <Icon component={MoveSvg} {...props} />
)
export default MoveIcon
