import React, { useEffect, useMemo, useState } from "react"
import { Tree } from "antd"
import { CaretDownOutlined } from "@ant-design/icons"
import { IFile } from "../../interfaces/content-interfaces"
import { useRouter } from "next/router"
import classNames from "./file-outliner.module.scss"
import _ from "lodash"
import { DataNode } from "rc-tree/lib/interface"
import TreeNode from "./tree-node"
import { t } from "i18next"

export const makeFileTree = (files: IFile[]): DataNode[] => {
  let temp: { [key: string]: DataNode } = {}

  const folders = _.orderBy(
    files.filter(file => file.mimeType === "application/am-folder"),
    "name"
  )

  folders.map(file => {
    const { id } = file
    temp[id] = {
      key: id,
      // icon: parentId ? folderIcon : rootIcon,
      // title: parentId ? name : organization?.name || name,
      children: [],
      title: <TreeNode file={file} isRoot={!file.parentId} />,
    }
  })

  folders.map(folder => {
    const parentId = folder.parentId
    if (parentId) {
      if (temp[parentId]) {
        const children = temp[parentId].children || []
        children.push(temp[folder.id])
        temp[parentId].children = children
      }
    }
  })

  const result: DataNode[] = []

  folders.map(folder => {
    const parentId = folder.parentId
    if (!parentId) {
      result.push(temp[folder.id])
    }
  })

  return result
}

export interface FileOutlinerProps {
  files: IFile[]
  currentFolderId?: string | null | undefined
  onFolderSelect?: (folderId: string) => void
  onFolderExpand?: (folderId: string) => void
  onLoadData?: ({ key, children }: any) => Promise<void>
}

const FileOutliner: React.FC<FileOutlinerProps> = ({
  files,
  currentFolderId,
  onFolderExpand,
  onFolderSelect,
  onLoadData,
}) => {
  const treeData = useMemo(() => {
    return makeFileTree(files)
  }, [files])

  const router = useRouter()

  const [expandedKeys, setExpandedKeys] = useState(
    currentFolderId ? [currentFolderId] : []
  )

  const [autoExpandParent, setAutoExpandParent] = useState(true)

  useEffect(() => {
    setExpandedKeys(_.uniq([...expandedKeys, currentFolderId || ""]))
  }, [currentFolderId, files])

  return (
    <div className={classNames.container}>
      <p className={classNames.title}>{t("menu:fileManager")}</p>
      <Tree
        className={classNames.tree}
        autoExpandParent={autoExpandParent}
        showIcon
        selectedKeys={currentFolderId ? [currentFolderId] : []}
        defaultExpandedKeys={currentFolderId ? [currentFolderId] : []}
        expandedKeys={expandedKeys}
        showLine={{ showLeafIcon: false }}
        switcherIcon={<CaretDownOutlined />}
        treeData={treeData}
        multiple={false}
        loadData={onLoadData}
        onSelect={keys => {
          const key = keys[0]
          if (!key) {
            return
          }
          const folderId = key.toString()
          router.push(`/folders/${folderId}`)
          onFolderSelect && onFolderSelect(folderId)
        }}
        onExpand={(keys, { expanded, node }) => {
          setAutoExpandParent(false)
          setExpandedKeys(keys as string[])

          if (expanded) {
            onFolderExpand && onFolderExpand(keys[0].toString())
          }
        }}
      />
    </div>
  )
}

export default FileOutliner
