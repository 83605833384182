import { IFile } from "../../interfaces/content-interfaces"
import React, { useEffect, useRef } from "react"
import { useDrive } from "../../hooks/use-drive"
import { useDrag, useDrop } from "react-dnd"
import { DragAndDropTypes } from "../../constants/enums"
import {
  canFileBeDroppedIn,
  getPrettyFilename,
  uiHandleError,
} from "../../utils"
import axios from "axios"
import classNames from "./tree-node.module.scss"
import { DragSet } from "../../interfaces/application-interfaces"
import { getEmptyImage } from "react-dnd-html5-backend"

interface TreeNodeProps {
  file: IFile
  isRoot?: boolean
}

const TreeNode: React.FC<TreeNodeProps> = ({ file, isRoot = false }) => {
  const { fileList, setFileList } = useDrive()
  const ref = useRef<HTMLDivElement | null>(null)

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      // "type" is required. It is used by the "accept" specification of drop targets.
      type: DragAndDropTypes.File,
      // The collect function utilizes a "monitor" instance (see the Overview for what this is)
      // to pull important pieces of state from the DnD system.
      item: file,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [file]
  )

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DragAndDropTypes.File,
      canDrop: (item: IFile | DragSet, monitor) => {
        if ("fileIds" in item) {
          const { file: droppingFile, fileIds } = item

          return (
            file.mimeType === "application/am-folder" &&
            droppingFile.id !== file.id &&
            !fileIds.includes(file.id)
          )
        } else {
          return (
            file.mimeType === "application/am-folder" && item.id !== file.id
          )
        }
      },
      drop: async (item: IFile | DragSet, monitor) => {
        if ("fileIds" in item) {
          const { file: droppingFile, fileIds } = item

          if (!canFileBeDroppedIn(droppingFile, file)) {
            return
          }

          try {
            await axios.post(`/api/v1/files/move`, {
              targetId: file.id,
              fileIds: fileIds,
            })

            const newList = [...fileList]
            newList
              .filter(f => fileIds.includes(f.id))
              .forEach(f => (f.parentId = file.id))

            setFileList([...newList])
          } catch (e: any) {
            uiHandleError(e)
          }
        } else {
          if (!canFileBeDroppedIn(item, file)) {
            return
          }

          try {
            await axios.post(`/api/v1/files/${item.id}/move`, {
              targetId: file.id,
            })

            const fileToUpdate = fileList.find(f => f.id === item.id)

            if (fileToUpdate) {
              fileToUpdate.parentId = file.id
              setFileList([...fileList])
            }
          } catch (e: any) {
            uiHandleError(e)
          }
        }
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [fileList, file]
  )

  const wrapperClasses = [classNames.container]

  if (isRoot) {
    wrapperClasses.push(classNames.root)
  }

  if (isOver) {
    wrapperClasses.push(classNames.highlighted)
  }

  drag(drop(ref))

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  return (
    <div ref={ref} className={wrapperClasses.join(" ")}>
      {getPrettyFilename(file)}
    </div>
  )
}

export default TreeNode
