import { ButtonProps } from "antd/lib/button/button"

export const MAX_UPLOAD_FILE = 0
export const MAX_DOWNLOAD_FILE = 5
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,25}$/
export const DEFAULT_PAGE_SIZE = 10
export const MAX_BREADCRUMB_LENGTH = 3
export const DEFAULT_PREVIEW = "https://via.placeholder.com/200"
export const CONTEXT_MENU_WIDTH = 311
export const PREVIEW_WIDTH = 1920
export const PREVIEW_HEIGHT = 1920

export const THUMBNAIL_WIDTH = 400
export const THUMBNAIL_HEIGHT = 380

export const POP_CONFIRM_BUTTON_PROPS: ButtonProps = {
  size: "large",
}

export const NOT_UPLOADABLE_FILE_LIST = ["DS_Store"]
