import React, { useEffect, useState } from "react"
import {
  IActivity,
  IComment,
  IFile,
  IPermission,
} from "../../interfaces/content-interfaces"
import {
  Button,
  Col,
  Comment,
  Descriptions,
  Divider,
  Empty,
  Input,
  List,
  Row,
  Space,
  Tabs,
  Timeline,
  Typography,
} from "antd"
import { CloseOutlined } from "@ant-design/icons"
import {
  formatDate,
  formatFileSize,
  getPrettyFilename,
  getUserAvatar,
  uiHandleError,
} from "../../utils"
import { useTranslation } from "react-i18next"
import useSWR from "swr"
import axios from "axios"
import useActivities from "../../hooks/use-activities"
import _ from "lodash"
import { LoadingView } from "../feedback"
import { PermissionTypes, Roles } from "../../constants/enums"
import useFilePermissions from "../../hooks/use-file-permissions"
import { canEditFile } from "../../utils/permission-check"
import { useUser } from "@auth0/nextjs-auth0"
import { TextEditor } from "../text-editor"
import FileTagEditor from "../tag-editor/file-tag-editor"
import ClassNames from "./file-detail-view.module.scss"
import { UserAvatar } from "../user"

export interface FileDetailViewProps {
  files: IFile[]
  onClose?: () => void
  closeIcon?: boolean
}

interface DetailViewProps {
  file?: IFile
}

const UserActivity = ({ activity }: { activity: IActivity }) => {
  return (
    <div>
      {activity.user && <UserAvatar user={activity.user} showName={true} />}
      <br />
      <Row justify="space-between">
        <Col>
          <Typography.Text>{activity.actionType}</Typography.Text>
        </Col>
        <Col>
          <Typography.Text type="secondary">
            {formatDate(activity.createdAt)}
          </Typography.Text>
        </Col>
      </Row>
    </div>
  )
}

const DetailView: React.FC<DetailViewProps> = ({ file }) => {
  const { t } = useTranslation()
  const { permissions } = useFilePermissions(file?.id)
  const { user } = useUser()
  const canEdit = canEditFile(permissions, user)
  const [currentFile, setCurrentFile] = useState<IFile | undefined>(
    file && { ...file }
  )
  const { data, error, isValidating } = useSWR(
    file ? `/api/v1/files/${file.id}/permissions` : null,
    url =>
      axios({
        withCredentials: true,
        method: "GET",
        url,
      }).then(res => res.data)
  )

  const handleDescriptionChange = async (newDescription: string) => {
    try {
      if (canEdit && currentFile) {
        await axios.post(`/api/v1/files/${currentFile.id}`, {
          description: newDescription,
        })

        if (currentFile) {
          setCurrentFile({ ...currentFile, description: newDescription })
        }
      }
    } catch (e: any) {
      uiHandleError(e)
    }
  }

  return (
    <div>
      <Typography.Title level={4}>{t("label:whoHasAccess")}</Typography.Title>
      <Space>
        {data?.permissions
          ?.filter(
            (permission: IPermission) =>
              permission.type === PermissionTypes.User
          )
          .map((permission: IPermission, index: number) => {
            const { user, role } = permission
            if (!user) {
              return null
            }

            return <UserAvatar key={index} user={user} role={role} />
          })}
      </Space>
      <Divider />
      <Typography.Title level={4}>{t("label:fileDetail")}</Typography.Title>
      <Descriptions bordered size="small" column={1}>
        <Descriptions.Item label={t("label:fileType")}>
          {currentFile?.mimeType}
        </Descriptions.Item>
        <Descriptions.Item label={t("label:fileSize")}>
          {formatFileSize(currentFile?.size)}
        </Descriptions.Item>
        <Descriptions.Item label={t("label:fileCreator")}>
          {file?.owner && (
            <UserAvatar
              user={file.owner}
              role={Roles.Owner}
              readOnly={!canEdit}
              onChange={async userId => {
                const response = await axios.post(
                  `/api/v1/files/${file!.id}/change-owner`,
                  {
                    newOwner: userId,
                  }
                )
                return response
              }}
            />
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t("label:fileCreatedAt")}>
          {formatDate(currentFile?.createdAt)}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <TextEditor
        type={"textarea"}
        title={t("label:fileDescription")}
        value={currentFile?.description || ""}
        onChange={handleDescriptionChange}
        readonly={!canEdit}
      />
      <Divider />
      {currentFile && <FileTagEditor file={currentFile} />}
    </div>
  )
}

const ActivityView: React.FC<DetailViewProps> = ({ file }) => {
  const { activities } = useActivities(file?.id)

  if (!activities?.length) {
    return <Empty />
  }

  return (
    <Timeline>
      {(activities || []).map((activity, index) => (
        <Timeline.Item key={index}>
          <UserActivity activity={activity} />
        </Timeline.Item>
      ))}
    </Timeline>
  )
}

const CommentsView: React.FC<DetailViewProps> = ({ file }) => {
  const { t } = useTranslation()
  const [textToSend, setTextToSend] = useState("")
  const [comments, setComments] = useState<IComment[]>([])

  const [shouldFetch, setShouldFetch] = useState(true)
  const { data, error } = useSWR<{ comments: IComment[] }>(
    shouldFetch && file ? `/api/v1/files/${file.id}/comments` : null,
    url =>
      axios({
        withCredentials: true,
        method: "GET",
        url,
      }).then(res => res.data)
  )

  useEffect(() => {
    if (data) {
      const newComments = [...data.comments, ...comments]
      setComments(_.uniqBy(newComments, "id"))
    }
  }, [data, error])

  const refetch = () => {
    setShouldFetch(false)
    setTimeout(() => {
      setShouldFetch(true)
    }, 300)
  }

  const postComment = async () => {
    try {
      await axios.post(`/api/v1/files/${file?.id}/comments`, {
        content: textToSend,
      })

      setTextToSend("")
      refetch()
    } catch (error: any) {
      uiHandleError(error)
    }
  }

  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={comments}
        locale={{ emptyText: <Empty description={t("label:noComments")} /> }}
        renderItem={(item, index) => (
          <li key={index}>
            <Comment
              actions={[]}
              author={item.user?.email}
              avatar={getUserAvatar(item.user)}
              content={item.content}
              datetime={formatDate(item.createdAt)}
            />
          </li>
        )}
      />
      <Input.TextArea
        showCount
        placeholder={t("placeholder:typeMessage")}
        maxLength={1000}
        value={textToSend}
        rows={5}
        onChange={evt => {
          setTextToSend(evt.target.value)
        }}
      />
      <Button
        type="primary"
        disabled={!textToSend}
        onClick={() => {
          postComment()
        }}
      >
        {t("button:send")}
      </Button>
    </div>
  )
}

const FileDetailView: React.FC<FileDetailViewProps> = ({
  files,
  onClose,
  closeIcon = true,
}) => {
  const { t } = useTranslation()

  const isSingleFile = files.length === 1
  const noFileSelected = files.length === 0

  const { data, error, isValidating } = useSWR(
    isSingleFile ? `/api/v1/files/${files[0].id}` : null,
    url =>
      axios({
        withCredentials: true,
        method: "GET",
        url,
      }).then(res => res.data)
  )

  if (isValidating) {
    return <LoadingView />
  }

  if (noFileSelected) {
    return (
      <Empty
        description={t("label:noFileSelected")}
        className={ClassNames["item-name-container"]}
      />
    )
  }

  return (
    <div className={ClassNames["info-container"]}>
      <Row
        justify="space-between"
        align="middle"
        className={ClassNames["item-name-container"]}
      >
        <Col>
          {isSingleFile ? (
            getPrettyFilename(files[0])
          ) : (
            <Typography.Text>
              {files.length} {t("label:selectedFile")}
            </Typography.Text>
          )}
        </Col>
        <Col>
          {closeIcon && (
            <Button
              className={ClassNames["close-icon"]}
              icon={<CloseOutlined />}
              onClick={() => {
                onClose && onClose()
              }}
            />
          )}
        </Col>
      </Row>
      {isSingleFile && (
        <Tabs className={ClassNames["item-detail-container"]}>
          <Tabs.TabPane tab={t("label:fileDetail")} key="fileDetail">
            <DetailView file={data?.file} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("label:fileComments")} key="fileComments">
            <CommentsView file={data?.file} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("label:fileActivities")} key="fileActivities">
            <ActivityView file={data?.file} />
          </Tabs.TabPane>
        </Tabs>
      )}
    </div>
  )
}

export default FileDetailView
