import moment from "moment"
import { IFile, IUser } from "../interfaces/content-interfaces"
import React, { useEffect, useState } from "react"
import { message, Space, Spin, Typography } from "antd"
import i18n from "../../lib/i18n"
import axios, { AxiosError } from "axios"
import { UserProfile } from "@auth0/nextjs-auth0/src/frontend/use-user"
import {
  ImageViewer,
  OfficeViewer,
  PdfViewer,
  UnsupportedFileViewer,
  VideoViewer,
} from "../components/file-viewer"
import { BAD_REQUEST } from "../constants/errors"
import FolderIcon from "../components/custom-icons/folder-icon"
import ImageFileIcon from "../components/custom-icons/image-icon"
import {
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileZipOutlined,
  SoundOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons"
import { IViewerProps } from "../interfaces/application-interfaces"
import { NOT_UPLOADABLE_FILE_LIST } from "../constants/app-config"

export const devLog =
  process.env.NODE_ENV !== "production" ? console.log : (): void => {}

export const formatDate = (
  date: Date | string | null | undefined,
  format = "lll"
) => {
  if (!date) {
    return i18n.t("label:invalidDate")
  }

  const momentDate = moment(date)

  const diff = -momentDate.diff(moment(), "day")

  if (diff < 2) {
    return momentDate.fromNow()
  }

  if (!momentDate.isValid()) {
    return i18n.t("label:invalidDate")
  }

  return momentDate.format(format)
}

export const createError = (
  error: string | Error,
  statusCode: number,
  description: string | null = null
) => {
  let message = error instanceof Error ? error.message : error

  return {
    message,
    statusCode,
    description,
  }
  // return new CustomError(message, statusCode, description)
}

export const getAllParents = (file: IFile | null | undefined): IFile[] => {
  if (!file) {
    return []
  }
  let fileToCheck = file
  const result: IFile[] = []
  while (fileToCheck.parent) {
    result.push(fileToCheck.parent)
    fileToCheck = fileToCheck.parent
  }

  result.reverse()

  result.push(file)

  return result
}

export const uiHandleError = (error: Error | AxiosError<any>) => {
  devLog({ error })

  let _message = "generic"
  if ("response" in error) {
    _message =
      error.response?.data?.description ||
      error.response?.data?.message ||
      error.response?.data?.error?.message ||
      _message
  } else if ("request" in error) {
    // case request has no response
    _message = "noResponse"
  }

  _message = i18n.t(`error:${_message}`)

  message.error(_message, 3)
}

export const getFileIcon = (mimeType: string): React.ReactNode => {
  // TODO setup icons for common files
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  const mainType = mimeType.split("/")[0]

  const customIconProps = {
    width: "1em",
    height: "1em",
    fill: "currentColor",
    style: {
      fontSize: "1.6em",
    },
  }

  const icons: { [key: string]: React.ReactNode } = {
    file: <FileOutlined {...customIconProps} />,
    "application/am-folder": <FolderIcon {...customIconProps} />,
    image: <ImageFileIcon {...customIconProps} />,
    video: <VideoCameraOutlined {...customIconProps} />,
    audio: <SoundOutlined {...customIconProps} />,
    text: <FileTextOutlined {...customIconProps} />,
    "application/pdf": <FilePdfOutlined {...customIconProps} />,
    "application/zip": <FileZipOutlined {...customIconProps} />,
    "application/vnd.rar": <FileZipOutlined {...customIconProps} />,
    "application/vnd.ms-powerpoint": <FilePptOutlined {...customIconProps} />,
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      <FilePptOutlined {...customIconProps} />,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": (
      <FileWordOutlined {...customIconProps} />
    ),
  }

  return icons[mainType] || icons[mimeType] || icons.file
}

export const formatFileSize = (
  bytes: number | null | undefined,
  si = false,
  dp = 1
): string => {
  if (!bytes) {
    return "-"
  }

  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + " B"
  }

  const units = si
    ? ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return bytes.toFixed(dp) + " " + units[u]
}

export const getUserAvatar = (
  user: IUser | UserProfile | null | undefined
): string => {
  const type = "bottts" // bottts

  return `https://avatars.dicebear.com/api/${type}/${encodeURIComponent(
    user?.email || ""
  )}.svg`
}

export const getFileViewer = (file: IFile): React.FC<IViewerProps> => {
  const supportedImageMimeTypeRegex = /image\/.*/

  const supportedVideoMimeTypeRegex = /video\/.*/

  const officeFileMimeTypes = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ]

  if (supportedImageMimeTypeRegex.test(file.mimeType)) {
    return ImageViewer
  } else if (supportedVideoMimeTypeRegex.test(file.mimeType)) {
    return VideoViewer
  } else if (file.mimeType === "application/pdf") {
    return PdfViewer
  } else if (officeFileMimeTypes.includes(file.mimeType)) {
    return OfficeViewer
  }

  return UnsupportedFileViewer
}

export const withFileChangeFeedback = (
  Component: React.FC<IViewerProps>
): React.FC<IViewerProps> => {
  const WrappedComponent: React.FC<IViewerProps> = props => {
    const { file } = props

    const [lastFile, setLastFile] = useState(file)

    useEffect(() => {
      setLastFile(file)
    }, [file])

    return file.id === lastFile.id ? (
      <Component {...props} />
    ) : (
      <Spin spinning />
    )
  }

  return WrappedComponent
}

export const getPrettyFilename = (
  file: IFile | null | undefined,
  maxWidth = 200
) => {
  if (!file) {
    return null
  }

  return (
    <Space align="center">
      {getFileIcon(file.mimeType)}
      <Typography.Text
        style={{ maxWidth, color: "inherit" }}
        ellipsis={{ tooltip: file.name }}
      >
        {file.name}
      </Typography.Text>
    </Space>
  )
}

export const canFileBeDroppedIn = (source: IFile, target: IFile) => {
  if (target.mimeType !== "application/am-folder") {
    devLog("Cannot drop file into non-folder file")
    return false
  }

  if (source.id === target.id) {
    // cannot drop file into self, this will break folder structure, make source file disappear from UI
    devLog("cannot drop file into self")
    return false
  }

  if (source.parentId === target.id) {
    devLog("file already in the folder")
    return false
  }

  const parentIds = getAllParents(target).map(item => item.id)

  if (parentIds.includes(source.id)) {
    // Cannot drop file into children, this will break folder structure, make source file disappear from UI
    devLog("cannot drop file into children")
    return false
  }

  return true
}

export const getFlatQueryParam = (
  param: string | string[] | undefined
): string => {
  const result = Array.isArray(param) ? param[0] : param

  if (!result) {
    throw createError(BAD_REQUEST, 400, `Missing ${param}`)
  }

  return result
}

export const downloadFile = async (file: IFile) => {
  try {
    axios.get(`/api/v1/files/${file.id}/download`, {}).then(response => {
      const { downloadUrl, filename } = response.data

      const link = document.createElement("a")
      link.href = downloadUrl
      link.target = "_blank"
      link.setAttribute("download", filename) //or any other extension
      link.click()
    })
  } catch (e: any) {
    uiHandleError(e)
  }
}

const originalFileMimeTypes = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
]

export const getPreviewS3Key = (file: IFile) => {
  const supportedVideoFormats = /video\/.*/gi

  if (supportedVideoFormats.test(file.mimeType)) {
    return `${file.s3Key}/video/master.m3u8`
  }

  if (originalFileMimeTypes.includes(file.mimeType)) {
    return `${process.env.S3_BUCKET_ROOT}/${file.s3Key}`
  }

  return `${file.s3Key}/preview`
}

export const getPreviewS3Bucket = (file: IFile): string => {
  if (originalFileMimeTypes.includes(file.mimeType)) {
    return process.env.S3_BUCKET ?? "arezzo-media"
  }

  return process.env.S3_BUCKET + "-resized"
}

export const isFileNotUploadable = (filename: string) => {
  const extension = filename.split(".").pop() as string

  return NOT_UPLOADABLE_FILE_LIST.includes(extension)
}
