import React from "react"
import { Col, Layout, Row } from "antd"
import UserMenu from "./user-menu"
import Link from "next/link"
import ClassNames from "./unauthenticated-layout.module.scss"
import logo from "../../../public/assets/images/arezzo-media-black.svg"
import { ImageWithFallback } from "../image-with-fallback"

export interface UnauthenticatedLayoutProps {}

const { Header, Footer, Sider, Content } = Layout

const UnauthenticatedLayout: React.FC<UnauthenticatedLayoutProps> = ({
  children,
}) => {
  return (
    <Layout>
      <Header className={ClassNames.header}>
        <Row justify="space-between">
          <Col className={ClassNames.logo}>
            <Link href="/">
              <ImageWithFallback src={logo.src} />
            </Link>
          </Col>
          <Col>
            <UserMenu />
          </Col>
        </Row>
      </Header>
      <Content>{children}</Content>
      {/*<Footer>Footer</Footer>*/}
    </Layout>
  )
}

export default UnauthenticatedLayout
