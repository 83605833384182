import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { IViewerProps } from "../../interfaces/application-interfaces"
import axios from "axios"
import { VideoJsPlayer, VideoJsPlayerOptions } from "video.js"
import { VideoPlayer } from "../video-player"
import { LoadingView } from "../feedback"

interface VideoViewerProps extends IViewerProps {}

const VideoViewer: React.FC<VideoViewerProps> = ({ file }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [originalFile, setOriginalFile] = useState<string | null>(null)

  const playerRef = useRef<VideoJsPlayer | null>(null)
  const [videoPlayerOptions, setVideoPlayerOptions] =
    useState<VideoJsPlayerOptions>({
      autoplay: true,
      controls: true,
      responsive: true,
      fill: true,
      techOrder: ["html5"],
      sources: [
        {
          src: `/api/v1/files/${file.id}/preview`,
          type: "application/x-mpegURL",
        },
      ],
    })

  useEffect(() => {
    axios
      .get(`/api/v1/files/${file.id}/download?noLog=1`)
      .then(res => {
        const source = res.data.downloadUrl
        setOriginalFile(source)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  if (loading) {
    return <LoadingView />
  }

  return (
    <VideoPlayer
      options={videoPlayerOptions}
      onReady={player => {
        playerRef.current = player
        player.on("error", () => {
          if (originalFile) {
            player.src({
              src: originalFile,
              type: "video/mp4",
            })
          }
        })
      }}
    />
  )
}

export default VideoViewer
