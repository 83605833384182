import React, { useEffect, useState } from "react"
import axios from "axios"
import useSWR from "swr"
import { ErrorView } from "../feedback"
import { Button, Select, Space, Spin } from "antd"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { IUser } from "../../interfaces/content-interfaces"
import { UserAvatar } from "./index"

export interface UserSelectorProps {
  value: string
  onChange?: (value: string | null | undefined) => void
  onSelect: (user: IUser | null) => void
  onCancel?: () => void
}

const UserSelector: React.FC<UserSelectorProps> = ({
  value,
  onChange,
  onSelect,
  onCancel,
}) => {
  const { data, error, isValidating } = useSWR(`/api/v1/users`, url =>
    axios.get(url).then(res => res.data)
  )

  const [currentUser, setCurrentUser] = useState<IUser | null>(null)

  useEffect(() => {
    if (data?.users) {
      const user = data.users.find((user: IUser) => user.userId === value)
      setCurrentUser(user)
    }
  }, [value, data])

  if (isValidating) {
    return <Spin spinning />
  }

  if (error) {
    return <ErrorView error={error} />
  }

  return (
    <Space size="small">
      <Select
        value={currentUser?.userId}
        showSearch
        style={{ width: 120 }}
        filterOption={(input, option) =>
          (option!.key as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        onChange={(userId: string) => {
          const user = data.users.find((user: IUser) => user.userId === userId)
          setCurrentUser(user)
        }}
      >
        {data?.users.map((user: IUser) => (
          <Select.Option key={user.email} value={user.userId}>
            <UserAvatar user={user} showName={true} />
          </Select.Option>
        ))}
      </Select>
      <Button
        type="primary"
        shape="circle"
        size="small"
        icon={<CheckOutlined />}
        onClick={() => {
          onChange && onChange(currentUser?.userId)
          onSelect(currentUser)
        }}
      />
      <Button
        shape="circle"
        size="small"
        icon={<CloseOutlined />}
        onClick={() => {
          onCancel && onCancel()
        }}
      />
    </Space>
  )
}

export default UserSelector
