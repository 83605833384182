import React from "react"
import { useTranslation } from "react-i18next"
import { IViewerProps } from "../../interfaces/application-interfaces"

interface OfficeViewerProps extends IViewerProps {}

const OfficeViewer: React.FC<OfficeViewerProps> = ({ file }) => {
  const { t } = useTranslation()
  return (
    <iframe
      id="msdoc-iframe"
      title="msdoc-iframe"
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        `${window.location.origin}/api/v1/files/${file.id}/preview`
      )}`}
      frameBorder="0"
    />
  )
}

export default OfficeViewer
