import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { IViewerProps } from "../../interfaces/application-interfaces"

import { Document, Page, pdfjs } from "react-pdf"
import { uiHandleError } from "../../utils"
import { LoadingView } from "../feedback"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

interface PdfViewerProps extends IViewerProps {}

const PdfViewer: React.FC<PdfViewerProps> = ({ file }) => {
  const { t } = useTranslation()
  const [numPages, setNumPages] = useState<number | null>(null)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
  }

  return (
    <div style={{ overflow: "auto", height: "100%" }}>
      <Document
        file={{ url: `/api/v1/files/${file.id}/preview` }}
        loading={<LoadingView />}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={error => uiHandleError(error)}
      >
        {Array.from({ length: numPages as number }, (_, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        ))}
      </Document>
    </div>
  )
}

export default PdfViewer
