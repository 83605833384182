import { IFile } from "../interfaces/content-interfaces"
import { useMemo, useState } from "react"
import axios from "axios"
import { uiHandleError } from "../utils"
import MoveFileModal from "../components/move-file/move-file-modal"

const useMoveFile = ([fileList, setFileList]: [
  IFile[],
  (files: IFile[]) => void
]) => {
  const [filesToMove, setFilesToMove] = useState<IFile[]>([])
  const [moving, setMoving] = useState(false)

  const moveFile = async (data: IFile[] | IFile) => {
    if (Array.isArray(data)) {
      setFilesToMove(data)
    } else {
      setFilesToMove([data])
    }
  }

  const closeModal = () => {
    setFilesToMove([])
  }

  const handleMoveFile = async (selectedFolder: IFile | null) => {
    setMoving(true)
    try {
      if (!filesToMove.length) {
        return
      }

      const fileIds = filesToMove.map(({ id }) => id)

      if (filesToMove.length === 1) {
        await axios.post(`/api/v1/files/${filesToMove[0].id}/move`, {
          targetId: selectedFolder?.id,
        })
      }

      if (filesToMove.length > 1) {
        await axios.post(`/api/v1/files/move`, {
          targetId: selectedFolder?.id,
          fileIds: fileIds,
        })
      }

      closeModal()
      const newList = [...fileList]
      newList
        .filter(item => fileIds.includes(item.id))
        .forEach(file => (file.parentId = selectedFolder?.id))

      setFileList([...newList])
    } catch (e: any) {
      uiHandleError(e)
    }
    setMoving(false)
  }

  const moveFileModal = useMemo(() => {
    return (
      <MoveFileModal
        files={filesToMove}
        onConfirm={handleMoveFile}
        onClose={closeModal}
        loading={moving}
      />
    )
  }, [filesToMove, handleMoveFile, moving])

  return {
    moveFile,
    moveFileModal,
  }
}

export default useMoveFile
