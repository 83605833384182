import React, { useEffect, useState } from "react"
import { Avatar, Button, List, Space, Spin } from "antd"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { IFile } from "../../interfaces/content-interfaces"
import classNames from "./move-file-modal.module.scss"
import FolderIcon from "../custom-icons/folder-icon"
import useSWR from "swr"
import axios from "axios"

export interface FolderPickerProps {
  organizationId: string
  onSelect: (folder: IFile | null) => void
}

const FolderPicker: React.FC<FolderPickerProps> = ({
  organizationId,
  onSelect,
}) => {
  const [selectedFolder, setSelectedFolder] = useState<IFile | null>(null)
  const { data, error, isValidating } = useSWR(
    organizationId
      ? [
          "/api/v1/files",
          selectedFolder?.id
            ? {
                parentId: selectedFolder?.id,
                mimeType: "application/am-folder",
                organizationId: organizationId,
              }
            : {
                mimeType: "application/am-folder",
                organizationId: organizationId,
              },
        ]
      : null,
    (url, params) =>
      axios({
        withCredentials: true,
        method: "GET",
        url,
        params,
      }).then(res => res.data)
  )

  useEffect(() => {
    onSelect(selectedFolder)
  }, [selectedFolder])

  return (
    <Spin spinning={isValidating}>
      <List
        header={
          Boolean(selectedFolder?.parent) && (
            <>
              <Space>
                <Button
                  shape="circle"
                  icon={<LeftOutlined />}
                  onClick={() => {
                    if (selectedFolder?.parent) {
                      setSelectedFolder(selectedFolder?.parent)
                    }
                  }}
                />
                <b>{selectedFolder?.name}</b>
              </Space>
            </>
          )
        }
        itemLayout="horizontal"
        dataSource={data?.files || []}
        renderItem={(item: IFile) => (
          <List.Item
            onClick={() => {
              const newFolder = { ...item, parent: selectedFolder }
              setSelectedFolder(newFolder)
            }}
            actions={[<RightOutlined key={"action1"} />]}
          >
            <List.Item.Meta
              className={classNames.folders}
              avatar={
                <Avatar
                  icon={<FolderIcon width={""} height={""} fill={""} />}
                />
              }
              title={item.name}
              description={null}
            />
          </List.Item>
        )}
      />
    </Spin>
  )
}

export default FolderPicker
