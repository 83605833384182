import React from "react"
import Icon from "@ant-design/icons"
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon"

const StarSvg: React.FC = () => (
  <svg width="1em" height="1em" viewBox="0 0 20.886 20.034">
    <path
      d="M17.416,22.03a1.682,1.682,0,0,1-.788-.2l-4.509-2.37a.241.241,0,0,0-.227,0l-4.508,2.37A1.691,1.691,0,0,1,4.93,20.051l.861-5.019a.244.244,0,0,0-.072-.217L2.075,11.258a1.691,1.691,0,0,1,.94-2.886l5.037-.731a.244.244,0,0,0,.184-.134l2.252-4.568a1.691,1.691,0,0,1,3.033,0l2.253,4.567a.246.246,0,0,0,.184.134L21,8.372a1.692,1.692,0,0,1,.94,2.886l-3.648,3.555a.245.245,0,0,0-.072.217l.861,5.019a1.693,1.693,0,0,1-1.664,1.978Zm-5.41-4.041a1.682,1.682,0,0,1,.787.2L17.3,20.553a.232.232,0,0,0,.258-.018.235.235,0,0,0,.1-.239l-.862-5.02a1.7,1.7,0,0,1,.486-1.5l3.648-3.555a.245.245,0,0,0-.136-.417l-5.04-.733a1.689,1.689,0,0,1-1.273-.925L12.223,3.582a.244.244,0,0,0-.438,0L9.533,8.147a1.689,1.689,0,0,1-1.274.925l-5.04.734a.244.244,0,0,0-.135.417l3.647,3.555a1.7,1.7,0,0,1,.486,1.5L6.356,20.3a.244.244,0,0,0,.356.258l4.507-2.37a1.689,1.689,0,0,1,.787-.195Z"
      transform="translate(-1.563 -1.996)"
      fill="currentColor"
    />
  </svg>
)

const StarIcon: React.FC<CustomIconComponentProps> = props => (
  <Icon component={StarSvg} {...props} />
)
export default StarIcon
