import React from "react"
import { Button, Col, Form, Input, Row, Select } from "antd"
import { Roles } from "../../constants/enums"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import {
  IFormProps,
  IPermissionInvitation,
} from "../../interfaces/application-interfaces"

interface MemberInvitationFormProps extends IFormProps {
  onFinish: (invitation: { users: IPermissionInvitation[] }) => Promise<void>
  notAllowed: string[]
}

const MemberInvitationForm: React.FC<MemberInvitationFormProps> = ({
  form,
  onFinish,
  useSubmitButton = true,
  notAllowed = [],
}) => {
  const { t } = useTranslation()
  return (
    <Form
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{ users: [{ email: "", role: Roles.Editor }] }}
      preserve={false}
    >
      <Form.List name="users">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Row key={key} gutter={[16, 16]}>
                <Col flex="auto">
                  <Form.Item
                    {...restField}
                    name={[name, "email"]}
                    fieldKey={[fieldKey, "email"]}
                    rules={[
                      { required: true, message: t("formError:emailRequired") },
                      { type: "email", message: t("formError:emailNotValid") },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (notAllowed.includes(value)) {
                            return Promise.reject(
                              new Error(t("error:alreadyHasPermission"))
                            )
                          } else {
                            return Promise.resolve()
                          }
                        },
                      }),
                    ]}
                  >
                    <Input placeholder="Email" size={"large"} />
                  </Form.Item>
                </Col>
                <Col flex="150px">
                  <Form.Item
                    {...restField}
                    name={[name, "role"]}
                    fieldKey={[fieldKey, "role"]}
                    rules={[
                      { required: true, message: t("formError:roleRequired") },
                    ]}
                  >
                    <Select placeholder={t("label:role")}>
                      <Select.Option value={Roles.Admin}>
                        {Roles.Admin}
                      </Select.Option>
                      <Select.Option value={Roles.Editor}>
                        {Roles.Editor}
                      </Select.Option>
                      <Select.Option value={Roles.Author}>
                        {Roles.Author}
                      </Select.Option>
                      <Select.Option value={Roles.Reader}>
                        {Roles.Reader}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col flex="20px">
                  <Form.Item shouldUpdate={true}>
                    {fields.length > 1 && (
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                type="link"
                onClick={() => add({ email: "", role: Roles.Editor })}
                icon={<PlusOutlined />}
              >
                {t("button:inviteMore")}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      {useSubmitButton && (
        <>
          <Form.Item>
            <Button block type="primary" htmlType="submit" size="large">
              {t("button:invite")}
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  )
}

export default MemberInvitationForm
