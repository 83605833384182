import { IFile } from "../interfaces/content-interfaces"
import React, { createContext, useContext, useState } from "react"
import { useDrive } from "./use-drive"
import { ContextMenu } from "../components/context-menu"
import { CONTEXT_MENU_WIDTH } from "../constants/app-config"
import { useUploader } from "./use-uploader"
import { Modal } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import MoveFileModal from "../components/move-file/move-file-modal"

export interface FolderViewContextMenuMouseEvent<T> extends MouseEvent {
  data: T
}

export type FolderViewContextMenuHandler = (
  event: FolderViewContextMenuMouseEvent<IFile[]>
) => void

interface ContextMenuContextTypes {
  onContextMenu: FolderViewContextMenuHandler
}

const ContextMenuContext = createContext<ContextMenuContextTypes>({
  onContextMenu: evt => {},
})

interface ContextMenuProviderProps {}

export const ContextMenuProvider: React.FC<ContextMenuProviderProps> = ({
  children,
}) => {
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [contextMenuPosition, setContextMenuPosition] = useState<
    [number, number]
  >([0, 0])
  const router = useRouter()

  const { t } = useTranslation()

  const [files, setFiles] = useState<IFile[]>([])

  const driveActions: any = useDrive()
  const { uploadFile } = useUploader()

  const onContextMenu = (evt: FolderViewContextMenuMouseEvent<IFile[]>) => {
    setFiles(evt.data)
    let x = evt.clientX
    if (x + CONTEXT_MENU_WIDTH > window.innerWidth) {
      x -= CONTEXT_MENU_WIDTH
    }
    let y = evt.clientY

    setContextMenuPosition([x, y])
    setShowContextMenu(true)
  }

  const handleMenuClick = async (key: string) => {
    setShowContextMenu(false)
    const actions = { ...driveActions, uploadFile }

    if (files.length === 0) {
      const action = actions[key]

      if (action) {
        const actionButton = document.getElementById(`${key}Button`)
        if (actionButton) {
          actionButton.click()
        }
        return
      } else {
        console.warn(`"${key}" action is not implemented in useDrive hook!`)
      }
    }

    if (files.length === 1) {
      const action = actions[key]

      if (action) {
        action(files[0])
        return
      } else {
        console.warn(`"${key}" action is not implemented in useDrive hook!`)
      }
    }

    // TODO multi file actions
    switch (key) {
      case "moveFile":
        driveActions.moveFile(files)
        break
      case "deleteFile":
        Modal.confirm({
          title: t("label:deleteFile"),
          icon: <ExclamationCircleOutlined />,
          content: t("warning:deleteFile"),
          okText: t("button:confirm"),
          cancelText: t("button:cancel"),
          onOk: async () => {
            try {
              const fileToDeleteIds = files.map(({ id }) => id)

              await axios.delete(`/api/v1/files`, {
                data: {
                  fileIds: fileToDeleteIds,
                },
              })

              driveActions.reload()
            } catch (error) {
              console.error({ deleteFileError: error })
            }
          },
          onCancel: () => {},
        })
        break
      default:
        break
    }
  }

  return (
    <ContextMenuContext.Provider
      value={{
        onContextMenu,
      }}
    >
      {children}
      <ContextMenu
        files={files}
        onClick={handleMenuClick}
        onCancel={() => {
          setShowContextMenu(false)
        }}
        position={contextMenuPosition}
        visible={showContextMenu}
      />
    </ContextMenuContext.Provider>
  )
}

const useContextMenu = () => useContext(ContextMenuContext)

export default useContextMenu
