import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Input, Space, Typography } from "antd"
import { EditOutlined } from "@ant-design/icons"

export interface TextEditorProps {
  title: string | null
  value: string
  readonly?: boolean
  onChange?: (value: string) => void
  type: "input" | "textarea"
}

const TextEditor: React.FC<TextEditorProps> = ({
  title,
  value,
  readonly,
  onChange,
  type = "input",
}) => {
  const { t } = useTranslation()
  const [editing, setEditing] = useState(false)
  const [text, setText] = useState(value)

  useEffect(() => {
    setText(value)
  }, [value])

  if (type === "input") {
    return (
      <div>
        {editing ? (
          <Input.Group compact>
            <Input
              style={{ width: "calc(100% - 200px)" }}
              onChange={evt => {
                setText(evt.target.value)
              }}
              defaultValue={value}
              onPressEnter={() => {
                onChange && onChange(text)
                setEditing(false)
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                onChange && onChange(text)
                setEditing(false)
              }}
              disabled={value === text || !text}
            >
              {t("button:save")}
            </Button>
            <Button
              danger
              onClick={() => {
                setText(value)
                setEditing(false)
              }}
            >
              {t("button:cancel")}
            </Button>
          </Input.Group>
        ) : (
          <Space>
            <span>{value}</span>
            {!readonly && (
              <EditOutlined
                onClick={() => {
                  setEditing(true)
                }}
              />
            )}
          </Space>
        )}
      </div>
    )
  }

  return (
    <div>
      <Typography.Title level={4}>
        {title}
        {!editing && !readonly && (
          <EditOutlined
            onClick={() => {
              setEditing(true)
            }}
          />
        )}
      </Typography.Title>
      {editing ? (
        <div>
          <Input.TextArea
            value={text}
            rows={5}
            onChange={evt => {
              setText(evt.target.value)
            }}
          />
          <Button
            danger
            onClick={() => {
              setText(value)
              setEditing(false)
            }}
          >
            {t("button:cancel")}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              onChange && onChange(text)
              setEditing(false)
            }}
            disabled={value === text || !text}
          >
            {t("button:save")}
          </Button>
        </div>
      ) : (
        <p>{value}</p>
      )}
    </div>
  )
}

export default TextEditor
