import React, { useEffect, useMemo, useRef, useState } from "react"
import useFilePermissions from "../../hooks/use-file-permissions"
import { IFile, ITag } from "../../interfaces/content-interfaces"
import { useUser } from "@auth0/nextjs-auth0"
import { canEditFile, canManageTags } from "../../utils/permission-check"
import {
  Col,
  Divider,
  Input,
  Row,
  Select,
  Tag,
  Tooltip,
  Typography,
} from "antd"
import { useTranslation } from "react-i18next"
import { PlusOutlined } from "@ant-design/icons"
import { uiHandleError } from "../../utils"
import axios from "axios"
import useSWR from "swr"
import { ErrorView } from "../feedback"
import { TAG_ALREADY_EXISTS } from "../../constants/errors"

interface TagSelectorProps {
  organizationId: string
  onSelect?: (tag: ITag) => void
  canCreateTag: boolean
  exclude?: string[]
}

const TagSelector: React.FC<TagSelectorProps> = ({
  organizationId,
  onSelect,
  canCreateTag,
  exclude,
}) => {
  const { t } = useTranslation()
  const [shouldFetch, setShouldFetch] = useState(true)
  const { data, error, isValidating } = useSWR(
    shouldFetch ? `/api/v1/organizations/${organizationId}/tags` : null,
    url =>
      axios({
        method: "GET",
        url,
      }).then(res => res.data)
  )

  const [tagToAdd, setTagToAdd] = useState("")

  const refetch = () => {
    setShouldFetch(false)
    setTimeout(() => {
      setShouldFetch(true)
    }, 300)
  }

  const handleAddTag = async () => {
    try {
      const allTags =
        data && data.tags.map((tag: ITag) => tag.name.toLowerCase())
      if (allTags.includes(tagToAdd.toLowerCase())) {
        throw new Error(TAG_ALREADY_EXISTS)
      }
      await axios.post(`/api/v1/organizations/${organizationId}/tags`, {
        name: tagToAdd,
        color: "#000000",
      })
      setTagToAdd("")
      refetch()
    } catch (e: any) {
      uiHandleError(e)
    }
  }

  if (error) {
    return <ErrorView error={error} />
  }

  return (
    <Select
      showSearch
      placeholder={t("placeholder:selectTag")}
      style={{ minWidth: 200, width: "100%" }}
      value={[]}
      onSelect={value => {
        const tag = data.tags.find((t: ITag) => t.id === value)
        onSelect && onSelect(tag)
      }}
      dropdownRender={menu => (
        <div>
          {menu}
          {canCreateTag && (
            <>
              <Divider style={{ margin: "4px 0" }} />
              <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                <Input
                  style={{ flex: "auto" }}
                  value={tagToAdd}
                  onChange={evt => {
                    setTagToAdd(evt.target.value)
                  }}
                />
                <a
                  style={{
                    flex: "none",
                    padding: "8px",
                    display: "block",
                    cursor: "pointer",
                  }}
                  onClick={handleAddTag}
                >
                  <PlusOutlined /> {t("button:createTag")}
                </a>
              </div>
            </>
          )}
        </div>
      )}
    >
      {data
        ? data.tags
            .filter((tag: ITag) => !(exclude || []).includes(tag.id))
            .map((tag: ITag, index: number) => (
              <Select.Option key={index} value={tag.id}>
                <span style={{ color: tag.color }}>{tag.name}</span>
              </Select.Option>
            ))
        : null}
    </Select>
  )
}

export interface TagEditorProps {
  file: IFile
}

const FileTagEditor: React.FC<TagEditorProps> = ({ file }) => {
  const { t } = useTranslation()
  const { permissions } = useFilePermissions(file.id)
  const { user } = useUser()
  const [tags, setTags] = useState<ITag[]>([])
  const [inputVisible, setInputVisible] = useState(false)

  const selectRef: any = useRef()

  useEffect(() => {
    if (file && file.tags) {
      const _tags: ITag[] = []
      file.tags.map(fileTag => {
        if (fileTag.tag) {
          _tags.push(fileTag.tag)
        }
      })

      setTags(_tags)
    }
  }, [file])

  const _canEditFile = useMemo<boolean>(() => {
    return canEditFile(permissions, user)
  }, [permissions, user])

  const _canManageTags = useMemo<boolean>(() => {
    return canManageTags(permissions, user)
  }, [permissions, user])

  const handleClose = async (tagToRemove: ITag) => {
    try {
      await axios.delete(`/api/v1/files/${file.id}/tags/${tagToRemove.id}`)
      setTimeout(() => {
        setTags(tags.filter(t => t.id !== tagToRemove.id))
      }, 500)
    } catch (e: any) {
      uiHandleError(e)
    }
  }

  const showInput = () => {
    selectRef.current?.focus()
    setInputVisible(true)
  }

  const handleTagSelect = async (tag: ITag) => {
    try {
      const _tag = tags.find(t => t.id === tag.id)
      if (_tag) {
        setInputVisible(false)
      }

      await axios.post(`/api/v1/files/${file.id}/tags`, { tagId: tag.id })
      setTags([...tags, tag])
      setInputVisible(false)
    } catch (e: any) {
      uiHandleError(e)
    }
  }

  return (
    <>
      <Typography.Title level={4}>{t("label:fileTags")}</Typography.Title>
      <Row gutter={[8, 8]}>
        {tags.map((tag, index) => {
          const isLongTag = tag.name.length > 20

          const tagElem = (
            <Tag
              key={tag.id}
              closable={_canEditFile}
              onClose={() => handleClose(tag)}
              color={tag.color}
            >
              {isLongTag ? `${tag.name.slice(0, 10)}...` : tag.name}
            </Tag>
          )

          return (
            <Col key={index}>
              {isLongTag ? (
                <Tooltip title={tag.name} key={tag.id}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              )}
            </Col>
          )
        })}
      </Row>
      <br />

      <TagSelector
        exclude={tags.map(t => t.id)}
        organizationId={file.organizationId}
        onSelect={handleTagSelect}
        canCreateTag={_canManageTags}
      />
    </>
  )
}

export default FileTagEditor
