// import "../../wdyr"

import "../../styles/globals.scss"
import type { AppProps } from "next/app"
import { UserProvider } from "@auth0/nextjs-auth0"
import { ConfigProvider, Spin } from "antd"
import it_IT from "antd/lib/locale/it_IT"

import "../../lib/i18n"
import { GlobalProvider } from "../hooks/use-global-value"
import BasicLayout from "../components/layout/basic-layout"
import { DriveProvider } from "../hooks/use-drive"
import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import { SWRConfig } from "swr"
import moment from "moment"
import "moment/locale/it"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { ContextMenuProvider } from "../hooks/use-context-menu"
import { ErrorView } from "../components/feedback"

moment.locale("it")

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  const [loadingPage, setLoadingPage] = useState(false)

  const handleLoadingStart = () => {
    setLoadingPage(true)
  }

  const handleLoadingStop = () => {
    setLoadingPage(false)
  }

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      window.document.addEventListener("contextmenu", evt =>
        evt.preventDefault()
      )
    }

    router.events.on("routeChangeStart", handleLoadingStart)
    router.events.on("routeChangeComplete", handleLoadingStop)
    router.events.on("routeChangeError", handleLoadingStop)

    return () => {
      router.events.off("routeChangeStart", handleLoadingStart)
      router.events.off("routeChangeComplete", handleLoadingStop)
      router.events.off("routeChangeError", handleLoadingStop)
    }
  }, [])

  // if (pageProps.error) {
  //   return (
  //     <Error
  //       statusCode={pageProps.error.statusCode}
  //       title={pageProps.error.message}
  //     />
  //   )
  // }

  const pageContent = pageProps.error ? (
    <ErrorView error={pageProps.error} />
  ) : (
    <Component {...pageProps} />
  )

  return (
    <DndProvider backend={HTML5Backend}>
      <ConfigProvider locale={it_IT} componentSize="large">
        <UserProvider>
          <GlobalProvider>
            <SWRConfig value={{ revalidateOnFocus: false }}>
              <DriveProvider>
                <ContextMenuProvider>
                  <BasicLayout>
                    <Spin spinning={loadingPage}>{pageContent}</Spin>
                  </BasicLayout>
                </ContextMenuProvider>
              </DriveProvider>
            </SWRConfig>
          </GlobalProvider>
        </UserProvider>
      </ConfigProvider>
    </DndProvider>
  )
}

export default MyApp
