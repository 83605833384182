import React from "react"
import { IFileUploadState } from "../../interfaces/application-interfaces"
import { Button, Col, List, Progress, Row } from "antd"
import { FileStreamingStatus } from "../../constants/enums"
import { useTranslation } from "react-i18next"
import { CheckOutlined, DeleteFilled, StopOutlined } from "@ant-design/icons"

export interface UploadFileMonitorProps {
  fileUploadStateList: IFileUploadState[]
  onCancel?: (fileState: IFileUploadState) => void
  onRemove?: (fileState: IFileUploadState) => void
}

type ProgressStatuses = "normal" | "exception" | "active" | "success"

export const UploadFileMonitor: React.FC<UploadFileMonitorProps> = ({
  fileUploadStateList,
  onCancel,
  onRemove,
}) => {
  const { t } = useTranslation()

  return (
    <List
      dataSource={fileUploadStateList}
      renderItem={fileState => {
        let progressStatus: ProgressStatuses = "active"
        if (
          [FileStreamingStatus.Failed, FileStreamingStatus.Cancelled].includes(
            fileState.status
          )
        ) {
          progressStatus = "exception"
        }

        if (fileState.status === FileStreamingStatus.UploadCompleted) {
          progressStatus = "success"
        }

        return (
          <List.Item>
            {fileState.file.name}
            <Row align="middle" gutter={16}>
              <Col flex="auto">
                <Progress
                  percent={Math.floor(fileState.progress * 100)}
                  status={progressStatus}
                />
              </Col>
              <Col flex="50px">
                {fileState.status === FileStreamingStatus.Uploading &&
                  fileState.cancelTokenSource && (
                    <Button
                      danger
                      shape="circle"
                      icon={<StopOutlined />}
                      onClick={() => {
                        onCancel && onCancel(fileState)
                      }}
                    />
                  )}

                {[
                  FileStreamingStatus.Failed,
                  FileStreamingStatus.Cancelled,
                ].includes(fileState.status) && (
                  <Button
                    shape="circle"
                    icon={<DeleteFilled />}
                    onClick={() => {
                      onRemove && onRemove(fileState)
                    }}
                  />
                )}

                {fileState.status === FileStreamingStatus.UploadCompleted && (
                  <Button
                    shape="circle"
                    icon={<CheckOutlined />}
                    onClick={() => {
                      onRemove && onRemove(fileState)
                    }}
                  />
                )}
              </Col>
            </Row>
          </List.Item>
        )
      }}
    />
  )
}

export default UploadFileMonitor
