import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react"
import { IFile } from "../interfaces/content-interfaces"
import axios from "axios"
import { UploaderProvider } from "./use-uploader"
import { downloadFile, uiHandleError } from "../utils"
import { useRouter } from "next/router"
import _ from "lodash"
import useSWR from "swr"
import { NOT_IMPLEMENTED } from "../constants/errors"
import { Modal } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import useShareFile from "./use-share-file"
import useMoveFile from "./use-move-file"
import usePreviewFile from "./use-preview-file"
import useRenameFile from "./use-rename-file"
import { IFileUploadState } from "../interfaces/application-interfaces"

interface DriveProviderProps {
  children: ReactElement
}

export interface DriveContextType {
  fileList: IFile[]
  currentFolder?: IFile | null
  currentFolderId?: string | null | undefined
  getFolderContent: (folderId: string) => Promise<void>
  createFolder: (parentId: string, folderName: string) => Promise<string>
  createFile: (
    parentId: string,
    file: IFileUploadState,
    s3Key: string
  ) => Promise<void>
  deleteFile: (file: IFile) => Promise<void>
  openParentFolder: (file: IFile) => Promise<void>
  addToFavorite: (file: IFile) => Promise<void>
  shareFile: (file: IFile) => Promise<void>
  moveFile: (file: IFile) => Promise<void>
  cloneFile: (file: IFile) => Promise<void>
  downloadFile: (file: IFile) => Promise<void>
  previewFiles: (files: IFile[], index: number) => Promise<void>
  restoreFile: (file: IFile) => Promise<void>
  renameFile: (file: IFile) => Promise<void>
  setFileList: (fileList: IFile[]) => void
  loading: boolean
  error: any
  reload: () => void
}

export const DriveContext = createContext<DriveContextType>(
  {} as DriveContextType
)

export const DriveProvider = ({ children }: DriveProviderProps) => {
  const router = useRouter()
  const { t } = useTranslation()

  const [currentFolder, setCurrentFolder] = useState<IFile | null>(null)
  const [currentFolderId, setCurrentFolderId] = useState<
    string | null | undefined
  >(null)

  const [fileList, setFileList] = useState<IFile[]>([])

  // get root folders
  const { data: rootFolderListData, error: rootFolderListError } = useSWR(
    ["/api/v1/files", {}],
    (url, params) =>
      axios({
        withCredentials: true,
        method: "GET",
        url,
        params,
      }).then(res => res.data)
  )

  // TODO refactoring folder loading logic

  // get children folders by folder id
  const {
    data: childFolderListData,
    error: childFolderListError,
    isValidating,
    mutate,
  } = useSWR(
    currentFolderId ? ["/api/v1/files", { parentId: currentFolderId }] : null,
    (url, params) =>
      axios({
        withCredentials: true,
        method: "GET",
        url,
        params,
      }).then(res => res.data)
  )

  // get children folders by folder id
  const { data: parentFolderListData, error: parentFolderListError } = useSWR(
    currentFolderId ? [`/api/v1/files/${currentFolderId}/parents`] : null,
    url =>
      axios({
        withCredentials: true,
        method: "GET",
        url,
      }).then(res => res.data)
  )

  const refetch = async () => {
    mutate()
  }

  const createFolder = async (
    parentId?: string,
    folderName?: string
  ): Promise<string> => {
    const currentFolderId = router.query.folderId as string

    const folderData = {
      name: folderName || t("label:newFolder"),
      mimeType: "application/am-folder",
      parentId: parentId || currentFolderId,
    }

    const response = await axios.post("/api/v1/files", folderData)
    const fileId = response.data?.file?.id

    refetch()

    return fileId
  }

  const createFile = async (
    parentId: string,
    fileData: IFileUploadState,
    s3Key: string
  ) => {
    const file = fileData.file
    const extensionMatch = file.name.match(/\.[0-9a-z]+$/i)

    await axios.post("/api/v1/files", {
      s3Key,
      name: file.name,
      originalFilename: file.name,
      extension: extensionMatch ? extensionMatch[0] : null,
      mimeType: file.type,
      size: file.size,
      parentId: parentId,
      width: fileData.width,
      height: fileData.height,
      exif: fileData.exif,
    })

    refetch()
  }

  useEffect(() => {
    if (childFolderListData || rootFolderListData || parentFolderListData) {
      let newFiles = childFolderListData
        ? [...fileList, ...childFolderListData.files]
        : [...fileList]
      newFiles = rootFolderListData
        ? [...newFiles, ...rootFolderListData.files]
        : newFiles

      newFiles = parentFolderListData
        ? [...newFiles, ...parentFolderListData.files]
        : newFiles

      newFiles = _.uniqBy(newFiles, "id")
      newFiles.map(file => {
        if (file.parentId) {
          const parent = newFiles.find(({ id }) => id === file.parentId)
          if (parent) {
            file.parent = parent

            const children = parent.children || []
            children.push(file)
            parent.children = children
          }
        }
      })

      setFileList(newFiles)
    }
  }, [childFolderListData, rootFolderListData, parentFolderListData])

  useEffect(() => {
    const folderId = Array.isArray(router.query.folderId)
      ? router.query.folderId[0]
      : router.query.folderId

    setCurrentFolderId(folderId)
  }, [router])

  const getFolderContent = async (folderId: string) => {
    try {
      const response = await axios.get(`/api/v1/files?parentId=${folderId}`)
      if (response.status === 200) {
        setFileList(_.uniqBy([...fileList, ...response.data.files], "id"))
      }
    } catch (e: any) {
      uiHandleError(e)
    }
  }

  const deleteFile = async (file: IFile) => {
    Modal.confirm({
      title: t("label:deleteFile"),
      icon: <ExclamationCircleOutlined />,
      content: t("warning:deleteFile"),
      okText: t("button:confirm"),
      cancelText: t("button:cancel"),
      onOk: async () => {
        try {
          await axios.delete(`/api/v1/files/${file.id}`, {})
          const newFileList = [...fileList]
          _.remove(newFileList, ({ id }) => id === file.id)
          setFileList(newFileList)
          mutate({ files: [...newFileList] })
        } catch (error) {
          console.error({ deleteFileError: error })
        }
      },
      onCancel: () => {},
    })
  }

  const restoreFile = async (file: IFile) => {
    Modal.confirm({
      title: t("label:restoreFile"),
      icon: <ExclamationCircleOutlined />,
      content: t("warning:restoreFile"),
      okText: t("button:confirm"),
      cancelText: t("button:cancel"),
      onOk: async () => {
        try {
          await axios.post(`/api/v1/files/${file.id}/restore`, {})
          router.replace(router.asPath)
        } catch (error) {
          console.error({ restoreFileError: error })
        }
      },
      onCancel: () => {},
    })
  }

  const openParentFolder = async (file: IFile) => {
    if (file.parentId) {
      await router.push(`/folders/${file.parentId}`)
    }
  }

  const addToFavorite = async (file: IFile) => {
    try {
      await axios.post(`/api/v1/files/${file.id}/favorite`, {})
    } catch (error: any) {
      uiHandleError(error)
      console.error({ addToFavoriteError: error })
    }
  }

  const { shareFile, shareFileModal } = useShareFile()
  const { moveFile, moveFileModal } = useMoveFile([fileList, setFileList])
  const { previewFiles, previewFileModal } = usePreviewFile()
  const { renameFile } = useRenameFile([fileList, setFileList])

  const cloneFile = async (file: IFile) => {
    throw new Error(NOT_IMPLEMENTED)
  }

  return (
    <DriveContext.Provider
      value={{
        deleteFile,
        restoreFile,
        fileList,
        currentFolder,
        currentFolderId,
        getFolderContent,
        createFolder,
        createFile,
        openParentFolder,
        addToFavorite,
        shareFile,
        moveFile,
        cloneFile,
        downloadFile,
        previewFiles,
        setFileList,
        renameFile,
        loading: isValidating,
        reload: () => {
          router.reload()
          // mutate()
        },
        error:
          rootFolderListError || childFolderListError || parentFolderListError,
      }}
    >
      {shareFileModal}
      {moveFileModal}
      {previewFileModal}
      <UploaderProvider>{children}</UploaderProvider>
    </DriveContext.Provider>
  )
}

export const useDrive = () => useContext(DriveContext)
