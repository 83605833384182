import React, { useMemo } from "react"
import { useGlobalValue } from "../../hooks/use-global-value"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { Col, Layout, Menu, Row } from "antd"
import { LoadingView } from "../feedback"
import UserMenu from "./user-menu"
import { FileOutliner } from "../drive-outliner"
import {
  DeleteFilled,
  FieldTimeOutlined,
  HeartOutlined,
  ShareAltOutlined,
} from "@ant-design/icons"
import Link from "next/link"
import { useDrive } from "../../hooks/use-drive"

import classNames from "./authenticated-layout.module.scss"
import SearchBar from "../search-bar"
import StarIcon from "../custom-icons/star"
import TrashIcon from "../custom-icons/trash"
import LinkIcon from "../custom-icons/link"

export interface AuthenticatedLayoutProps {}

const { Header, Sider, Content } = Layout

const mainCenterColSettings = {
  xs: 20,
  sm: 20,
  md: 20,
  lg: 20,
  xl: 12,
  xxl: 12,
}

const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({
  children,
}) => {
  const [{ isReady }, dispatch] = useGlobalValue()
  const { fileList, currentFolderId, getFolderContent } = useDrive()

  const router = useRouter()
  const { t } = useTranslation()

  const fileOutliner = useMemo(() => {
    return (
      <FileOutliner
        files={fileList}
        currentFolderId={currentFolderId}
        onLoadData={async ({ key }) => {
          router.push(`/folders/${key}`)
          // await getFolderContent(key)
        }}
      />
    )
  }, [fileList, currentFolderId])

  if (!isReady) {
    return <LoadingView />
  }

  const pathToHideSideBarRegex = /(\/settings)/i

  const hideSidebar = pathToHideSideBarRegex.test(router.pathname)

  return (
    <Layout className={classNames.container}>
      {!hideSidebar && (
        <Sider className={classNames.sidebar} width={288}>
          <Link passHref href={"/"}>
            <a>
              {/*<Image src={logo} alt="Logo" />*/}
              <h1 className={classNames["sider-title"]}>
                {t("menu:siderTitle")}
              </h1>
            </a>
          </Link>
          {fileOutliner}
          <Menu selectedKeys={[router.pathname]} className={classNames.menu}>
            <Menu.ItemGroup key="menu.extra" title={t("menu:extra")} />
            <Menu.Item
              key="/favorites"
              icon={<StarIcon width={""} height={""} fill={""} />}
            >
              <Link href="/favorites">{t("menu:favorites")}</Link>
            </Menu.Item>
            <Menu.Item
              key="/trashed"
              icon={<TrashIcon width={""} height={""} fill={""} />}
            >
              <Link href="/trashed">{t("menu:trashed")}</Link>
            </Menu.Item>
            <Menu.Item
              key="/shared"
              icon={<LinkIcon width={""} height={""} fill={""} />}
            >
              <Link href="/shared">{t("menu:sharedWithMe")}</Link>
            </Menu.Item>
            <Menu.Item key="/recent" icon={<FieldTimeOutlined />}>
              <Link href="/recent">{t("menu:recent")}</Link>
            </Menu.Item>
          </Menu>
        </Sider>
      )}
      <Layout>
        <Header className={classNames.header}>
          <Row justify="space-between" align="middle">
            <Col>
              {hideSidebar ? <Link href="/">logo</Link> : <SearchBar />}
            </Col>
            <Col>
              <UserMenu />
            </Col>
          </Row>
        </Header>
        <Content className={classNames["content-container"]}>
          {hideSidebar ? (
            <Row justify="center">
              <Col {...mainCenterColSettings}>{children}</Col>
            </Row>
          ) : (
            <>{children}</>
          )}
        </Content>
      </Layout>
    </Layout>
  )
}

export default AuthenticatedLayout
