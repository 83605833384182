import { useTranslation } from "react-i18next"
import { IFile } from "../interfaces/content-interfaces"
import { Form, Modal } from "antd"
import { RenameFileForm } from "../components/form"
import axios from "axios"
import { EditOutlined } from "@ant-design/icons"

const useRenameFile = ([fileList, setFileList]: [
  IFile[],
  (fileList: IFile[]) => void
]) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const renameFile = async (file: IFile) => {
    form.setFieldsValue({ name: file.name })

    Modal.confirm({
      title: t("label:renameFile"),
      icon: <EditOutlined />,
      content: <RenameFileForm form={form} useSubmitButton={false} />,
      onOk() {
        return new Promise((resolve, reject) => {
          form
            .validateFields()
            .then(values => {
              if (values.name === file.name) {
                resolve(values)
                return
              }

              axios.post(`/api/v1/files/${file.id}`, values).then(response => {
                if (response.status === 200) {
                  let newFileList = [...fileList]
                  const _file = newFileList.find(item => item.id === file.id)
                  if (_file) {
                    _file.name = values.name
                  }
                  setFileList([...newFileList])
                  resolve(values)
                } else {
                  reject()
                }
              })
            })
            .catch(errorInfo => {
              reject(errorInfo)
            })
        })
      },
      onCancel() {},
    })
  }

  return {
    renameFile,
  }
}

export default useRenameFile
