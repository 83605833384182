import { IFile } from "../interfaces/content-interfaces"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { getFileViewer, withFileChangeFeedback } from "../utils"
import { Button, Modal } from "antd"
import { ViewerTools } from "../components/file-viewer"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import classNames from "../components/folder-view/folder-view.module.scss"

const prevButtonClass = [classNames["nav-button"], classNames["prev"]].join(" ")
const nextButtonClass = [classNames["nav-button"], classNames["next"]].join(" ")

const usePreviewFile = () => {
  const { t } = useTranslation()
  const [fileList, setFileList] = useState<IFile[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false)
  const [previousButtonEnabled, setPreviousButtonEnabled] = useState(false)

  const [fileToPreview, setFileToPreview] = useState<IFile | null>(null)
  const [loadInfo, setLoadInfo] = useState(false)

  const previewFiles = async (files: IFile[], index = 0) => {
    setFileList(files)
    setCurrentIndex(index)
  }

  const nextFile = () => {
    setCurrentIndex(currentIndex + 1)
  }

  const previousFile = () => {
    setCurrentIndex(currentIndex - 1)
  }

  function handeShowInfo() {
    setLoadInfo(true)
  }

  useEffect(() => {
    setPreviousButtonEnabled(currentIndex > 0)
    setNextButtonEnabled(currentIndex < fileList.length - 1)
    setFileToPreview(fileList[currentIndex])
  }, [fileList, currentIndex])

  const previewFileModal = useMemo(() => {
    if (!fileToPreview) {
      return null
    }

    const Viewer = withFileChangeFeedback(getFileViewer(fileToPreview))

    return (
      <Modal
        className={"fullscreen viewer transparent"}
        title={<ViewerTools file={fileToPreview} onShowInfo={handeShowInfo} />}
        closeIcon={null}
        visible={Boolean(fileToPreview)}
        okText={t("button:preview")}
        onOk={() => {}}
        onCancel={() => {
          setCurrentIndex(-1)
        }}
        footer={null}
      >
        <Viewer file={fileToPreview} />
        {fileList.length > 1 && (
          <div className={classNames["file-viewer-controls"]}>
            {previousButtonEnabled && (
              <Button
                className={prevButtonClass}
                shape="circle"
                disabled={!previousButtonEnabled}
                onClick={previousFile}
                icon={<LeftOutlined />}
              />
            )}
            {nextButtonEnabled && (
              <Button
                className={nextButtonClass}
                shape="circle"
                disabled={!nextButtonEnabled}
                onClick={nextFile}
                icon={<RightOutlined />}
              />
            )}
          </div>
        )}
      </Modal>
    )
  }, [fileToPreview, loadInfo, t])

  return {
    previewFiles,
    previewFileModal,
  }
}

export default usePreviewFile
