import React, { useEffect, useState } from "react"
import { Button, Col, Modal, Row, Spin } from "antd"
import { IFile } from "../../interfaces/content-interfaces"
import { useTranslation } from "react-i18next"
import classNames from "./move-file-modal.module.scss"
import FolderPicker from "./folder-picker"

interface MoveFileModalProps {
  files: IFile[]
  onClose: () => void
  onConfirm: (folder: IFile | null) => Promise<void>
  loading: boolean
}

const MoveFileModal: React.FC<MoveFileModalProps> = ({
  onClose,
  onConfirm,
  files,
  loading,
}) => {
  const { t } = useTranslation()

  const [selectedFolder, setSelectedFolder] = useState<IFile | null>(null)

  useEffect(() => {
    if (files) {
      setSelectedFolder(null)
    }
  }, [files])

  return (
    <Modal
      className={classNames.container}
      title={t("label:moveFile")}
      visible={Boolean(files[0])}
      okText={t("button:move")}
      onOk={() => {}}
      onCancel={onClose}
      footer={
        <Row justify="space-between">
          <Col />
          <Col>
            <Button
              type="primary"
              disabled={!selectedFolder?.id || loading}
              onClick={() => {
                onConfirm(selectedFolder)
              }}
            >{`${t("button:moveTo")} ${selectedFolder?.name}`}</Button>
          </Col>
        </Row>
      }
    >
      <Spin spinning={loading}>
        {files[0] && (
          <FolderPicker
            organizationId={files[0].organizationId}
            onSelect={folder => setSelectedFolder(folder)}
          />
        )}
      </Spin>
    </Modal>
  )
}

export default MoveFileModal
