import { Button, Col, Popover, Row, Space } from "antd"
import { downloadFile, getPrettyFilename } from "../../utils"
import { DownloadOutlined, InfoCircleOutlined } from "@ant-design/icons"
import React from "react"
import { IFile } from "../../interfaces/content-interfaces"
import useFilePermissions from "../../hooks/use-file-permissions"
import { canDownloadFile } from "../../utils/permission-check"
import { useUser } from "@auth0/nextjs-auth0"
import FileDetailView from "../folder-view/file-detail-view"
import { useTranslation } from "react-i18next"

interface ViewerToolsProps {
  file: IFile
  onShowInfo: () => void
}

const ViewerTools: React.FC<ViewerToolsProps> = ({ file, onShowInfo }) => {
  const { user } = useUser()
  const { permissions } = useFilePermissions(file?.parentId)
  const canDownload = canDownloadFile(permissions, user)
  const { t } = useTranslation()

  return (
    <Row justify={"space-between"}>
      <Col span={4}>{getPrettyFilename(file)}</Col>
      <Col>
        <Space>
          {/*<Button icon={<PrinterOutlined />} />*/}
          {canDownload && (
            <Button
              title={t("button:download")}
              icon={<DownloadOutlined />}
              onClick={() => downloadFile(file)}
            />
          )}
          <Popover
            trigger="click"
            content={
              <div
                style={{
                  width: 350,
                  maxHeight: 500,
                  overflowY: "auto",
                }}
              >
                <FileDetailView files={[file]} closeIcon={false} />
              </div>
            }
          >
            <Button
              icon={<InfoCircleOutlined />}
              onClick={onShowInfo}
              title={t("button:learnMore")}
            />
          </Popover>
        </Space>
      </Col>
      <Col span={4} />
    </Row>
  )
}

export default ViewerTools
