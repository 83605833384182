import React, { useEffect, useState } from "react"
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  List,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
} from "antd"
import {
  formatDate,
  getPrettyFilename,
  getUserAvatar,
  uiHandleError,
} from "../../utils"
import { MemberInvitationForm } from "../form"
import { PermissionTypes, Roles } from "../../constants/enums"
import ClassNames from "./share-file-modal.module.scss"
import { IFile, IPermission } from "../../interfaces/content-interfaces"
import { useTranslation } from "react-i18next"
import { IPermissionInvitation } from "../../interfaces/application-interfaces"
import CopyIcon from "../custom-icons/copy"
import TrashIcon from "../custom-icons/trash"
import { POP_CONFIRM_BUTTON_PROPS } from "../../constants/app-config"
import { canShareFile } from "../../utils/permission-check"
import { useUser } from "@auth0/nextjs-auth0"
import _ from "lodash"

interface ShareFileProps {
  file: IFile | null
  permissions: IPermission[] | null
  onClose: () => void
  loading: boolean
  onInvite: (invitation: { users: IPermissionInvitation[] }) => Promise<void>
  onPermissionRemove: (permissionId: string) => void
  onRoleChange: (permissionId: string, role: Roles) => void
  onUrlAccessToggle: (permission: IPermission | null) => void
}

const ShareFileModal: React.FC<ShareFileProps> = ({
  file,
  permissions,
  onClose,
  loading,
  onInvite,
  onPermissionRemove,
  onRoleChange,
  onUrlAccessToggle,
}) => {
  const { t } = useTranslation()
  const { user } = useUser()

  const [form] = Form.useForm()

  const [urlAccessPermission, setUrlAccessPermission] =
    useState<IPermission | null>(null)
  const [canShare, setCanShare] = useState(false)
  const [urlAccessLink, setUrlAccessLink] = useState<string | null>(null)

  const urlAccessLinkInputRef = React.useRef<any>(null)

  useEffect(() => {
    if (!permissions) {
      return
    }
    const permission =
      permissions.find(item => item.type === PermissionTypes.Url) || null

    setUrlAccessPermission(permission)
  }, [permissions])

  useEffect(() => {
    const canShare = canShareFile(permissions, user)
    setCanShare(canShare)
  }, [permissions, user])

  useEffect(() => {
    if (file && urlAccessPermission) {
      const isFolder = file.mimeType === "application/am-folder"

      setUrlAccessLink(
        `${window.location.origin}/${isFolder ? "folders" : "files"}/${file.id}`
      )
    } else {
      setUrlAccessLink(null)
    }
  }, [file, urlAccessPermission])

  return (
    <Modal
      destroyOnClose
      title={t("label:shareFile")}
      visible={Boolean(file)}
      footer={null}
      onOk={() => {}}
      onCancel={() => {
        onClose()
      }}
      className={ClassNames.container}
    >
      <Spin spinning={loading}>
        <div>
          <div>
            <Row justify="space-between">
              <Col>{getPrettyFilename(file)}</Col>
              <Col>
                <Popconfirm
                  title={
                    urlAccessPermission
                      ? t("warning:removeUrlAccess")
                      : t("warning:makeUrlAccess")
                  }
                  onConfirm={() => {
                    onUrlAccessToggle(urlAccessPermission)
                  }}
                  okText={t("button:confirm")}
                  cancelText={t("button:cancel")}
                  okButtonProps={POP_CONFIRM_BUTTON_PROPS}
                  cancelButtonProps={POP_CONFIRM_BUTTON_PROPS}
                  disabled={!canShare}
                  overlayClassName={"toggle-url-access-popover"}
                >
                  <Switch
                    loading={loading}
                    checkedChildren={t("label:urlAccess")}
                    unCheckedChildren={t("label:privateAccess")}
                    disabled={!canShare}
                    checked={Boolean(urlAccessPermission)}
                  />
                </Popconfirm>
              </Col>
            </Row>

            {urlAccessLink && (
              <>
                <Divider orientation="left" plain>
                  {t("label:urlPublic")}
                </Divider>
                <Input.Group compact>
                  <Input
                    style={{ width: "calc(100% - 65px)" }}
                    ref={urlAccessLinkInputRef}
                    readOnly={true}
                    size={"large"}
                    value={urlAccessLink}
                    onClick={evt => {
                      urlAccessLinkInputRef.current?.focus({
                        cursor: "all",
                      })
                    }}
                  />
                  <Button
                    type="primary"
                    icon={
                      <CopyIcon
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        style={{ color: "white" }}
                      />
                    }
                    onClick={() => {
                      try {
                        navigator.clipboard.writeText(urlAccessLink)
                        message.success(t("message:linkCopied"))
                      } catch (e: any) {
                        uiHandleError(e)
                      }
                    }}
                  />
                </Input.Group>
              </>
            )}
          </div>
          <Divider orientation="left" plain>
            {t("label:share")}
          </Divider>
          <div className={ClassNames["member-invitation"]}>
            <MemberInvitationForm
              form={form}
              onFinish={async invitation => {
                try {
                  await onInvite(invitation)
                  form.resetFields()
                } catch (e: any) {
                  uiHandleError(e)
                }
              }}
              notAllowed={
                permissions?.map(permission => permission?.user?.email || "") ??
                []
              }
            />
          </div>
          <List
            itemLayout="horizontal"
            dataSource={_.orderBy(
              (permissions || []).filter(
                permission => permission.type === PermissionTypes.User
              ),
              "createdAt",
              "desc"
            )}
            renderItem={item => (
              <List.Item
                key={item.id}
                actions={
                  canShare
                    ? [
                        <Popconfirm
                          key={"removePermission"}
                          title={t("warning:removePermission")}
                          onConfirm={() => {
                            onPermissionRemove(item.id)
                          }}
                          okText={t("button:confirm")}
                          cancelText={t("button:cancel")}
                          okButtonProps={POP_CONFIRM_BUTTON_PROPS}
                          cancelButtonProps={POP_CONFIRM_BUTTON_PROPS}
                          disabled={item.isMember}
                        >
                          <Button
                            disabled={item.isMember}
                            ghost
                            danger
                            icon={
                              <TrashIcon
                                width="1em"
                                height="1em"
                                fill="currentColor"
                              />
                            }
                            type="link"
                          />
                        </Popconfirm>,
                      ]
                    : [<div key="empty" />]
                }
              >
                <List.Item.Meta
                  avatar={<Avatar src={getUserAvatar(item.user)} />}
                  title={item.user?.email}
                  description={formatDate(item.createdAt)}
                />
                <div>
                  <Select
                    onChange={value => {
                      onRoleChange(item.id, value as Roles)
                    }}
                    disabled={!canShare || item.isMember}
                    defaultValue={item.role}
                  >
                    <Select.Option value={Roles.Editor}>
                      {Roles.Editor}
                    </Select.Option>
                    <Select.Option value={Roles.Author}>
                      {Roles.Author}
                    </Select.Option>
                    <Select.Option value={Roles.Reader}>
                      {Roles.Reader}
                    </Select.Option>
                  </Select>
                </div>
              </List.Item>
            )}
          />
        </div>
      </Spin>
    </Modal>
  )
}

export default ShareFileModal
