import React from "react"
import { Button, Result } from "antd"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"

interface ErrorViewProps {
  error: any
}

const ErrorView: React.FC<ErrorViewProps> = ({ error }) => {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <Result
      status={"error"}
      title={t(`error:${error.message}`)}
      subTitle={error.description && t(`error:${error.description}`)}
      extra={[
        <Button key="back">{t("button:back")}</Button>,
        error.statusCode === 401 ? (
          <Button
            key="login"
            type="primary"
            onClick={() => {
              router.push(`/api/auth/login?returnTo=${router.asPath}`)
            }}
          >
            {t("button:login")}
          </Button>
        ) : null,
      ]}
    />
  )
}

export default ErrorView
