import useSWR from "swr"
import axios from "axios"
import { IActivity } from "../interfaces/content-interfaces"
import { useState } from "react"

interface UserActivitiesHook {
  activities: IActivity[] | null
  refetch: () => void
  error: Error
}

const useActivities = (
  fileId: string | null | undefined
): UserActivitiesHook => {
  const [shouldFetch, setShouldFetch] = useState(true)

  const { data, error } = useSWR(
    shouldFetch && fileId ? `/api/v1/files/${fileId}/activities` : null,
    url =>
      axios({
        withCredentials: true,
        method: "GET",
        url,
      }).then(res => res.data)
  )

  const refetch = () => {
    setShouldFetch(false)
    setTimeout(() => {
      setShouldFetch(true)
    }, 300)
  }

  if (error) {
    console.error({ getPermissionsError: error })
  }

  return {
    activities: shouldFetch ? data?.activities ?? null : null,
    error,
    refetch,
  }
}

export default useActivities
