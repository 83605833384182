import React from "react"
import { useTranslation } from "react-i18next"
import { useGlobalValue } from "../../hooks/use-global-value"
import { Avatar, Button, Popover, Space, Spin } from "antd"
import { useUser } from "@auth0/nextjs-auth0"
import Link from "next/link"
import { CaretDownOutlined, SettingOutlined } from "@ant-design/icons"
import { getUserAvatar } from "../../utils"
import classNames from "./user-menu.module.scss"
import { useRouter } from "next/router"

export interface UserMenuProps {}

const UserMenu: React.FC<UserMenuProps> = () => {
  const { t } = useTranslation()
  const [{}, dispatch] = useGlobalValue()
  const { user, isLoading, error } = useUser()
  const router = useRouter()

  if (isLoading) {
    return <Spin spinning />
  }

  if (!user) {
    return (
      <Space>
        <Button
          onClick={() => {
            router.push(`/api/auth/login?returnTo=${router.asPath}`)
          }}
        >
          {t("button:signIn")}
        </Button>
      </Space>
    )
  }

  const userMenu = (
    <div>
      <Space direction="vertical" align="center">
        <Space direction="vertical" align="center">
          <Avatar size={120} src={getUserAvatar(user)} />
          <span>{user.email}</span>
        </Space>
        <Link href={"/settings/account"} passHref>
          <Button>{t("button:manageYourAccount")}</Button>
        </Link>
        <Link href={"/api/auth/logout"} passHref>
          <Button danger>{t("button:signOut")}</Button>
        </Link>
      </Space>
    </div>
  )

  return (
    <Space>
      <Link href={"/settings"} passHref>
        <Button className={classNames["setting-button"]}>
          <SettingOutlined />
        </Button>
      </Link>
      <Popover content={userMenu} trigger="hover">
        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
          <Space className={classNames.container}>
            <Avatar
              size={32}
              src={getUserAvatar(user)}
              className={classNames.avatar}
            />
            <span>{user.email}</span>
            <CaretDownOutlined />
          </Space>
        </a>
      </Popover>
    </Space>
  )
}

export default UserMenu
