import React, { useState } from "react"
import { IUser } from "../../interfaces/content-interfaces"
import { Avatar, Space, Tooltip } from "antd"
import { getUserAvatar, uiHandleError } from "../../utils"
import { useTranslation } from "react-i18next"
import { EditOutlined } from "@ant-design/icons"
import UserSelector from "./user-selector"

export interface UserAvatarProps {
  user: IUser
  role?: string
  showName?: boolean
  readOnly?: boolean
  onChange?: (value: string | null | undefined) => void
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  user,
  role,
  showName = false,
  readOnly = true,
  onChange,
}) => {
  const { t } = useTranslation()

  const [editing, setEditing] = useState(false)

  const [currentUser, setCurrentUser] = useState(user)

  const displayText = role
    ? `${currentUser.email} | ${t("roles:" + role)}`
    : currentUser.email

  if (editing) {
    return (
      <UserSelector
        value={currentUser.userId}
        onChange={async userId => {
          if (!onChange) {
            return
          }

          try {
            await onChange(userId)
            setEditing(false)
          } catch (e: any) {
            uiHandleError(e)
          }
        }}
        onSelect={user => {
          user && setCurrentUser(user)
        }}
        onCancel={() => {
          setEditing(false)
        }}
      />
    )
  }

  return (
    <Tooltip title={displayText}>
      <Space>
        <Avatar size={32} src={getUserAvatar(currentUser)} />
        {showName && <h4>{displayText}</h4>}
        {!readOnly && !editing && (
          <EditOutlined
            onClick={() => {
              setEditing(true)
            }}
          />
        )}
      </Space>
    </Tooltip>
  )
}

export default UserAvatar
