import React from "react"
import Icon from "@ant-design/icons"
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon"

const PreviewSvg: React.FC = () => (
  <svg width="1em" height="1em" viewBox="0 0 21.081 17.667">
    <g transform="translate(0.35 -1.65)">
      <g transform="translate(-0.35 1.65)">
        <path
          d="M.427,6.267A.427.427,0,0,1,0,5.841V4.134A2.136,2.136,0,0,1,2.134,2H3.841a.427.427,0,1,1,0,.853H2.134a1.282,1.282,0,0,0-1.28,1.28V5.841A.427.427,0,0,1,.427,6.267Z"
          transform="translate(0.299 -1.701)"
          fill="currentColor"
        />
        <path
          d="M.375,6.515A.726.726,0,0,1-.35,5.789V4.082A2.435,2.435,0,0,1,2.082,1.65H3.789a.725.725,0,1,1,0,1.451H2.082a.983.983,0,0,0-.982.982V5.789A.726.726,0,0,1,.375,6.515ZM2.082,2.247A1.837,1.837,0,0,0,.247,4.082V5.789a.128.128,0,1,0,.256,0V4.082A1.581,1.581,0,0,1,2.082,2.5H3.789a.128.128,0,1,0,0-.256Z"
          transform="translate(0.35 -1.65)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(15.866 1.65)">
        <path
          d="M22.841,6.267a.427.427,0,0,1-.427-.427V4.134a1.282,1.282,0,0,0-1.28-1.28H19.427a.427.427,0,0,1,0-.853h1.707a2.136,2.136,0,0,1,2.134,2.134V5.841A.427.427,0,0,1,22.841,6.267Z"
          transform="translate(-18.701 -1.701)"
          fill="currentColor"
        />
        <path
          d="M22.789,6.515a.726.726,0,0,1-.725-.725V4.082a.983.983,0,0,0-.982-.982H19.375a.725.725,0,1,1,0-1.451h1.707a2.435,2.435,0,0,1,2.432,2.432V5.789A.726.726,0,0,1,22.789,6.515ZM19.375,2.247a.128.128,0,0,0,0,.256h1.707a1.581,1.581,0,0,1,1.579,1.579V5.789a.128.128,0,1,0,.256,0V4.082a1.837,1.837,0,0,0-1.835-1.835Z"
          transform="translate(-18.65 -1.65)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(-0.35 14.452)">
        <path
          d="M3.841,21.267H2.134A2.136,2.136,0,0,1,0,19.134V17.427a.427.427,0,1,1,.853,0v1.707a1.282,1.282,0,0,0,1.28,1.28H3.841a.427.427,0,0,1,0,.853Z"
          transform="translate(0.299 -16.701)"
          fill="currentColor"
        />
        <path
          d="M3.789,21.515H2.082A2.435,2.435,0,0,1-.35,19.082V17.375a.725.725,0,1,1,1.451,0v1.707a.983.983,0,0,0,.982.982H3.789a.725.725,0,1,1,0,1.451ZM.375,17.247a.128.128,0,0,0-.128.128v1.707a1.837,1.837,0,0,0,1.835,1.835H3.789a.128.128,0,1,0,0-.256H2.082A1.581,1.581,0,0,1,.5,19.082V17.375A.128.128,0,0,0,.375,17.247Z"
          transform="translate(0.35 -16.65)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(15.866 14.452)">
        <path
          d="M21.134,21.267H19.427a.427.427,0,0,1,0-.853h1.707a1.282,1.282,0,0,0,1.28-1.28V17.427a.427.427,0,1,1,.853,0v1.707A2.136,2.136,0,0,1,21.134,21.267Z"
          transform="translate(-18.701 -16.701)"
          fill="currentColor"
        />
        <path
          d="M21.082,21.515H19.375a.725.725,0,1,1,0-1.451h1.707a.983.983,0,0,0,.982-.982V17.375a.725.725,0,0,1,1.451,0v1.707A2.435,2.435,0,0,1,21.082,21.515Zm-1.707-.853a.128.128,0,1,0,0,.256h1.707a1.837,1.837,0,0,0,1.835-1.835V17.375a.128.128,0,0,0-.256,0v1.707a1.581,1.581,0,0,1-1.579,1.579Z"
          transform="translate(-18.65 -16.65)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(3.064 5.064)">
        <path
          d="M10.828,16.242a6.85,6.85,0,0,1-6.815-5.019.409.409,0,0,1,0-.2A6.85,6.85,0,0,1,10.828,6a6.85,6.85,0,0,1,6.815,5.019.409.409,0,0,1,0,.2A6.85,6.85,0,0,1,10.828,16.242ZM4.867,11.121a6.016,6.016,0,0,0,5.961,4.267,6.016,6.016,0,0,0,5.961-4.267,6.015,6.015,0,0,0-5.961-4.267A6.015,6.015,0,0,0,4.867,11.121Z"
          transform="translate(-3.701 -5.701)"
          fill="currentColor"
        />
        <path
          d="M10.777,16.489a7.148,7.148,0,0,1-7.105-5.247.706.706,0,0,1,0-.348,7.148,7.148,0,0,1,7.1-5.244A7.148,7.148,0,0,1,17.882,10.9a.706.706,0,0,1,0,.348A7.148,7.148,0,0,1,10.777,16.489Zm0-10.242a6.553,6.553,0,0,0-6.525,4.792.114.114,0,0,0,0,.058,6.554,6.554,0,0,0,6.526,4.795A6.553,6.553,0,0,0,17.3,11.1a.114.114,0,0,0,0-.058A6.554,6.554,0,0,0,10.777,6.247Zm0,9.388A6.769,6.769,0,0,1,6.8,14.39a5.807,5.807,0,0,1-2.276-3.244l-.02-.077.02-.077A5.807,5.807,0,0,1,6.8,7.749,6.769,6.769,0,0,1,10.777,6.5,6.769,6.769,0,0,1,14.75,7.749a5.807,5.807,0,0,1,2.276,3.244l.02.077-.02.077A5.807,5.807,0,0,1,14.75,14.39,6.769,6.769,0,0,1,10.777,15.636ZM5.126,11.07a5.746,5.746,0,0,0,5.651,3.969,5.746,5.746,0,0,0,5.651-3.969A5.746,5.746,0,0,0,10.777,7.1,5.746,5.746,0,0,0,5.126,11.07Z"
          transform="translate(-3.65 -5.65)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(7.331 7.624)">
        <path
          d="M11.56,14.121a2.56,2.56,0,1,1,2.56-2.56A2.564,2.564,0,0,1,11.56,14.121Zm0-4.267a1.707,1.707,0,1,0,1.707,1.707A1.709,1.709,0,0,0,11.56,9.853Z"
          transform="translate(-8.701 -8.701)"
          fill="currentColor"
        />
        <path
          d="M11.509,14.368a2.859,2.859,0,1,1,2.859-2.859A2.862,2.862,0,0,1,11.509,14.368Zm0-5.121a2.262,2.262,0,1,0,2.262,2.262A2.264,2.264,0,0,0,11.509,9.247Zm0,4.267a2.006,2.006,0,1,1,2.006-2.006A2.008,2.008,0,0,1,11.509,13.515Zm0-3.414a1.408,1.408,0,1,0,1.408,1.408A1.41,1.41,0,0,0,11.509,10.1Z"
          transform="translate(-8.65 -8.65)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
)

const PreviewIcon: React.FC<CustomIconComponentProps> = props => (
  <Icon component={PreviewSvg} {...props} />
)
export default PreviewIcon
