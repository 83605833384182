import React from "react"
import Icon from "@ant-design/icons"
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon"

const CopySvg: React.FC = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 20 20">
    <g transform="translate(-2 -2)">
      <path
        d="M20,2H8A2,2,0,0,0,6,4V6H4A2,2,0,0,0,2,8V20a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V18h2a2,2,0,0,0,2-2V4A2,2,0,0,0,20,2ZM16,20H4V8H6v8a2,2,0,0,0,2,2h8Zm4-4H8V4H20Z"
        fill="#currentColor"
      />
    </g>
  </svg>
)

const CopyIcon: React.FC<CustomIconComponentProps> = props => (
  <Icon component={CopySvg} {...props} />
)
export default CopyIcon
