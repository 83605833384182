import React from "react"
import Icon from "@ant-design/icons"
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon"

const DownloadSvg: React.FC = () => (
  <svg width="1em" height="1em" viewBox="0 0 20.199 20.199">
    <path
      d="M-4974.755-643.8a2.251,2.251,0,0,1-2.244-2.244v-4.489h2.244v4.489h15.71v-4.489h2.245v4.489a2.251,2.251,0,0,1-2.245,2.244Zm7.063-4.818-4.762-4.761,1.587-1.587,2.846,2.845V-664h2.244v11.88l2.845-2.845,1.588,1.587-4.762,4.761a1.117,1.117,0,0,1-.793.329A1.118,1.118,0,0,1-4967.693-648.618Z"
      transform="translate(4977 664)"
      fill="currentColor"
    />
  </svg>
)

const DownloadIcon: React.FC<CustomIconComponentProps> = props => (
  <Icon component={DownloadSvg} {...props} />
)
export default DownloadIcon
