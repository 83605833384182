import { Menu, Modal } from "antd"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  CheckCircleOutlined,
  EditOutlined,
  FolderAddOutlined,
  FolderOpenFilled,
  HeartOutlined,
  UploadOutlined,
} from "@ant-design/icons"
import useFilePermissions from "../../hooks/use-file-permissions"
import { MenuClickEventHandler } from "rc-menu/lib/interface"
import { useUser } from "@auth0/nextjs-auth0"
import {
  canCreateFile,
  canDeleteFile,
  canDownloadFile,
  canEditFile,
  canReadFile,
  canShareFile,
} from "../../utils/permission-check"
import classNames from "./context-menu.module.scss"

import Link from "next/link"
import PreviewIcon from "../custom-icons/preview"
import LinkIcon from "../custom-icons/link"
import FolderIcon from "../custom-icons/folder-icon"
import MoveIcon from "../custom-icons/move"
import DownloadIcon from "../custom-icons/download"
import TrashIcon from "../custom-icons/trash"
import { IFile } from "../../interfaces/content-interfaces"
import { CONTEXT_MENU_WIDTH } from "../../constants/app-config"
import { useRouter } from "next/router"

interface BaseContextMenuProps {
  onMenuClick: MenuClickEventHandler
}

interface SingleFileContextMenuProps extends BaseContextMenuProps {
  file: IFile
}

const SingleFileContextMenu: React.FC<SingleFileContextMenuProps> = ({
  file,
  onMenuClick,
}) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { permissions } = useFilePermissions(file.parentId)

  const trashed = Boolean(file.trashedAt)

  const canRead = canReadFile(permissions, user)
  const isFolder = file.mimeType === "application/am-folder"
  const canPreview = !isFolder && canRead

  const canShare = canShareFile(permissions, user) && !trashed

  const canEdit = canEditFile(permissions, user)
  const canMove = canEdit && !trashed
  const canRename = canEdit && !trashed

  const canCreate = canCreateFile(permissions, user)
  const canClone = canRead && canCreate && !trashed

  const canDownload = canPreview && canDownloadFile(permissions, user)

  const canDelete = canDeleteFile(permissions, user) && !trashed

  const canRestore = trashed && canRead && canCreate

  const canCanAddFavorite = Boolean(user) && !trashed
  return (
    <Menu mode="inline" onClick={onMenuClick} selectedKeys={[]}>
      {isFolder && (
        <>
          <Menu.Item key="openFolder" icon={<FolderOpenFilled />}>
            <Link href={`/folders/${file.id}`}>{t("button:openFolder")}</Link>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      {canPreview && (
        <>
          <Menu.Item
            key="previewFile"
            icon={
              <PreviewIcon height={"1em"} width={"1em"} fill={"currentColor"} />
            }
          >
            {t("button:previewFile")}
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      <Menu.Item
        key="shareFile"
        icon={<LinkIcon height={"1em"} width={"1em"} fill={"currentColor"} />}
        disabled={!canShare}
      >
        {t("button:shareFile")}
      </Menu.Item>
      <Menu.Item
        key="openParentFolder"
        icon={<FolderIcon height={"1em"} width={"1em"} fill={"currentColor"} />}
        disabled={!user || !file.parentId || trashed}
      >
        {t("button:openParentFolder")}
      </Menu.Item>
      <Menu.Item key="renameFile" icon={<EditOutlined />} disabled={!canRename}>
        {t("button:renameFile")}
      </Menu.Item>
      <Menu.Item
        key="moveFile"
        icon={<MoveIcon height={"1em"} width={"1em"} fill={"currentColor"} />}
        disabled={!canMove}
      >
        {t("button:moveFile")}
      </Menu.Item>
      <Menu.Item
        key="addToFavorite"
        icon={<HeartOutlined />}
        disabled={!canCanAddFavorite}
      >
        {t("button:addToFavorite")}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="downloadFile"
        icon={
          <DownloadIcon height={"1em"} width={"1em"} fill={"currentColor"} />
        }
        disabled={!canDownload}
      >
        {t("button:downloadFile")}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="deleteFile"
        icon={<TrashIcon height={"1em"} width={"1em"} fill={"currentColor"} />}
        disabled={!canDelete}
      >
        {t("button:deleteFile")}
      </Menu.Item>
      {canRestore && (
        <>
          <Menu.Divider />
          <Menu.Item key="restoreFile" icon={<CheckCircleOutlined />}>
            {t("button:restoreFile")}
          </Menu.Item>
        </>
      )}
    </Menu>
  )
}

interface MultipleFileContextMenuProps extends BaseContextMenuProps {
  files: IFile[]
}

const MultipleFileContextMenu: React.FC<MultipleFileContextMenuProps> = ({
  files,
  onMenuClick,
}) => {
  const { t } = useTranslation()
  const { user } = useUser()

  const file = files[0]

  const { permissions } = useFilePermissions(file.parentId)

  const trashed = Boolean(file.trashedAt)

  const canEdit = canEditFile(permissions, user)
  const canMove = canEdit && !trashed

  const canDelete = canDeleteFile(permissions, user) && !trashed

  return (
    <Menu mode="inline" onClick={onMenuClick} selectedKeys={[]}>
      <Menu.ItemGroup
        key="selectedFileCount"
        title={`${files.length} ${t("label:selectedFiles")}`}
      >
        <Menu.Item
          key="moveFile"
          icon={<MoveIcon height={"1em"} width={"1em"} fill={"currentColor"} />}
          disabled={!canMove}
        >
          {t("button:moveFile")}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="deleteFile"
          icon={
            <TrashIcon height={"1em"} width={"1em"} fill={"currentColor"} />
          }
          disabled={!canDelete}
        >
          {t("button:deleteFile")}
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )
}

export interface NoFileContextMenuProps extends BaseContextMenuProps {}

const NoFileContextMenu: React.FC<NoFileContextMenuProps> = ({
  onMenuClick,
}) => {
  const router = useRouter()

  const parentFolderId = router.query.folderId as string

  const { t } = useTranslation()
  const { user } = useUser()
  const { permissions } = useFilePermissions(parentFolderId)

  const canEdit = canEditFile(permissions, user)

  if (!parentFolderId) {
    return null
  }

  return (
    <Menu mode="inline" onClick={onMenuClick} selectedKeys={[]}>
      <Menu.Item
        key="createFolder"
        icon={<FolderAddOutlined height={"1em"} width={"1em"} />}
        disabled={!canEdit}
      >
        {t("button:newFolder")}
      </Menu.Item>
      <Menu.Item
        key="uploadFile"
        icon={<UploadOutlined height={"1em"} width={"1em"} />}
        disabled={!canEdit}
      >
        {t("button:upload")}
      </Menu.Item>
    </Menu>
  )
}

export interface ContextMenuProps {
  files: IFile[]
  onClick?: (action: string) => void
  onCancel?: () => void
  position: [number, number]
  width?: number
  visible: boolean
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  files,
  onClick,
  position = [0, 0],
  width = CONTEXT_MENU_WIDTH,
  onCancel,
  visible = false,
}) => {
  const handleMenuClick: MenuClickEventHandler = async ({ item, key }) => {
    onClick && onClick(key)
  }

  const menu = useMemo(() => {
    if (files.length === 0) {
      return <NoFileContextMenu onMenuClick={handleMenuClick} />
    }

    if (files.length === 1) {
      return (
        <SingleFileContextMenu file={files[0]} onMenuClick={handleMenuClick} />
      )
    }

    return (
      <MultipleFileContextMenu files={files} onMenuClick={handleMenuClick} />
    )
  }, [files])

  return (
    <Modal
      destroyOnClose
      title={null}
      footer={null}
      width={width}
      style={{
        left: position[0],
        top: position[1],
        margin: 0,
      }}
      maskStyle={{ visibility: "hidden" }}
      bodyStyle={{
        padding: 0,
        margin: 0,
      }}
      closable={false}
      visible={visible}
      onCancel={onCancel}
      className={classNames.container}
    >
      {menu}
    </Modal>
  )
}

export default ContextMenu
