import { Button, Input } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import React, { useState } from "react"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"

export interface SearchBarProps {}

const SearchBar = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState(
    Array.isArray(router.query.q) ? router.query.q[0] : router.query.q
  )

  return (
    <Input.Group compact>
      <Input
        allowClear
        prefix={<SearchOutlined />}
        style={{ width: 300 }}
        placeholder={t("label:searchInputPlaceholder")}
        value={searchText}
        onChange={evt => setSearchText(evt.target.value)}
        onPressEnter={() => {
          router.push(`/search?q=${searchText}`)
        }}
      />
      <Button
        type="primary"
        onClick={() => {
          router.push(`/search?q=${searchText}`)
        }}
      >
        {t("button:search")}
      </Button>
    </Input.Group>
  )
}

export default SearchBar
