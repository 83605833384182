import useSWR from "swr"
import axios from "axios"
import { IPermission } from "../interfaces/content-interfaces"

interface UserPermissionHook {
  permissions: IPermission[] | null
  refetch: () => void
  loading: boolean
  error: Error
}

const useFilePermissions = (
  fileId: string | null | undefined
): UserPermissionHook => {
  const { data, error, mutate, isValidating } = useSWR(
    fileId ? `/api/v1/files/${fileId}/permissions` : null,
    url =>
      axios({
        withCredentials: true,
        method: "GET",
        url,
      }).then(res => res.data)
  )

  const refetch = () => {
    mutate()
  }

  if (error) {
    console.error({ getPermissionsError: error })
  }

  return {
    permissions: data?.permissions ?? null,
    refetch,
    loading: isValidating,
    error,
  }
}

export default useFilePermissions
