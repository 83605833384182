export const LOGIN_REQUIRED = "LOGIN_REQUIRED"
export const INVALID_ID_TOKEN = "INVALID_ID_TOKEN"
export const INSUFFICIENT_PERMISSIONS = "INSUFFICIENT_PERMISSIONS"
export const CANNOT_DOWNLOAD_FOLDER = "CANNOT_DOWNLOAD_FOLDER"
export const BAD_REQUEST = "BAD_REQUEST"
export const ILLEGAL_OPERATION = "ILLEGAL_OPERATION"
export const NOT_IMPLEMENTED = "NOT_IMPLEMENTED"
export const INVALID_PARENT_ID = "INVALID_PARENT_ID"
export const INVALID_FOLDER_NAME = "INVALID_FOLDER_NAME"
export const INVALID_FILE = "INVALID_FILE"
export const METHOD_NOT_ALLOWED = "METHOD_NOT_ALLOWED"
export const PASSWORD_TOO_WEAK = "PASSWORD_TOO_WEAK"
export const TAG_ALREADY_EXISTS = "TAG_ALREADY_EXISTS"
export const NOT_FOUND_FILE = "NOT_FOUND_FILE"
export const NOT_FOUND_APP = "NOT_FOUND_APP"
export const LOAD_IMAGE_ERROR = "LOAD_IMAGE_ERROR"
