import React from "react"
import Icon from "@ant-design/icons"
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon"

const TrashSvg: React.FC = () => (
  <svg width="1em" height="1em" viewBox="0 0 19.631 20.559">
    <g transform="translate(-10.693 -9.029)">
      <path
        d="M13.95,21.237H27.067l-1,12.929a1.407,1.407,0,0,1-.271.758.41.41,0,0,1-.354.192H15.572a.41.41,0,0,1-.354-.192,1.4,1.4,0,0,1-.271-.758Zm-2.327-1.86a.93.93,0,0,0,0,1.86h.462l1.006,13.07a3.2,3.2,0,0,0,.695,1.8,2.356,2.356,0,0,0,1.786.867h9.872a2.354,2.354,0,0,0,1.787-.867,3.192,3.192,0,0,0,.693-1.8l1.007-13.07h.463a.93.93,0,1,0,0-1.86Z"
        transform="translate(0 -7.387)"
        fill="currentColor"
      />
      <path
        d="M35.62,9.029a.93.93,0,1,0,0,1.86h4.038a.93.93,0,1,0,0-1.86Z"
        transform="translate(-17.13)"
        fill="currentColor"
      />
    </g>
  </svg>
)

const TrashIcon: React.FC<CustomIconComponentProps> = props => (
  <Icon component={TrashSvg} {...props} />
)
export default TrashIcon
