import React from "react"
import { useTranslation } from "react-i18next"
import { IViewerProps } from "../../interfaces/application-interfaces"
import { Result } from "antd"

interface UnsupportedFileViewerProps extends IViewerProps {}

const UnsupportedFileViewer: React.FC<UnsupportedFileViewerProps> = ({
  file,
}) => {
  const { t } = useTranslation()
  return (
    <Result
      status="500"
      title={t("label:fileNotSupportedForPreview")}
      subTitle={file.mimeType}
    />
  )
}

export default UnsupportedFileViewer
