import React from "react"
import Icon from "@ant-design/icons"
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon"

const FolderSvg: React.FC = () => (
  <svg width="1em" height="1em" viewBox="0 0 19.808 15.563">
    <path
      d="M19.686,5.707H11.2A2.122,2.122,0,0,0,9.612,5H4.122A2.122,2.122,0,0,0,2,7.122V18.441a2.122,2.122,0,0,0,2.122,2.122H19.686a2.122,2.122,0,0,0,2.122-2.122V7.83A2.122,2.122,0,0,0,19.686,5.707Zm.707,2.122v.127a2.122,2.122,0,0,0-.707-.127h-6.9a.707.707,0,0,1-.637-.389l-.156-.318h7.7A.707.707,0,0,1,20.393,7.83Zm0,10.611a.707.707,0,0,1-.707.707H4.122a.707.707,0,0,1-.707-.707V7.122a.707.707,0,0,1,.707-.707h5.49a.707.707,0,0,1,.637.389l.63,1.266a2.122,2.122,0,0,0,1.9,1.174h6.9a.707.707,0,0,1,.707.707Z"
      transform="translate(-2 -5)"
      fill="currentColor"
    />
  </svg>
)

const FolderIcon: React.FC<CustomIconComponentProps> = props => (
  <Icon component={FolderSvg} {...props} />
)
export default FolderIcon
