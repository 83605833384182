import React from "react"
import { Button, Form, Input } from "antd"
import { useTranslation } from "react-i18next"
import { IFormProps } from "../../interfaces/application-interfaces"

interface RenameFileFormProps extends IFormProps {}

const RenameFileForm: React.FC<RenameFileFormProps> = ({
  initialValues,
  onFinish,
  form,
  useSubmitButton = true,
}) => {
  const { t } = useTranslation()
  return (
    <Form
      onFinish={onFinish}
      form={form}
      autoComplete="off"
      initialValues={initialValues}
      preserve={false}
    >
      <Form.Item
        name={"name"}
        rules={[
          { required: true, message: t("formError:filenameRequired") },
          {
            type: "string",
            max: 100,
            message: t("formError:filenameLengthExceeded"),
          },
        ]}
      >
        <Input placeholder={t("placeholder:filename")} />
      </Form.Item>
      {useSubmitButton && (
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("button:rename")}
          </Button>
        </Form.Item>
      )}
    </Form>
  )
}

export default RenameFileForm
