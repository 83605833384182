import React from "react"
import { useUser } from "@auth0/nextjs-auth0"
import AuthenticatedLayout from "./authenticated-layout"
import UnauthenticatedLayout from "./unauthenticated-layout"
import { ErrorView, LoadingView } from "../feedback"

export interface BasicLayoutProps {}

const BasicLayout: React.FC<BasicLayoutProps> = ({ children }) => {
  const { user, error, isLoading } = useUser()
  if (isLoading) {
    return <LoadingView />
  }

  if (error) {
    return (
      <UnauthenticatedLayout>
        <ErrorView error={error} />
      </UnauthenticatedLayout>
    )
  }

  return user ? (
    <AuthenticatedLayout>{children}</AuthenticatedLayout>
  ) : (
    <UnauthenticatedLayout>{children}</UnauthenticatedLayout>
  )
}

export default BasicLayout
