import React from "react"
import Icon from "@ant-design/icons"
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon"

const ImageFileSvg: React.FC = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 21 21">
    <path
      className="cls-1"
      d="M7.8,4.56A2.13,2.13,0,1,0,9.93,6.69h0A2.13,2.13,0,0,0,7.8,4.56Zm0,3.37h0A1.25,1.25,0,1,1,9.05,6.69,1.25,1.25,0,0,1,7.8,7.94Z"
    />
    <path
      className="cls-1"
      d="M16.08,2.75H4.92A2.17,2.17,0,0,0,2.75,4.92V16.08a2.17,2.17,0,0,0,2.17,2.17H16.08a2.17,2.17,0,0,0,2.17-2.17V4.92A2.17,2.17,0,0,0,16.08,2.75Zm.79,13.34a.78.78,0,0,1-.79.78H4.92a.78.78,0,0,1-.79-.78v-1.5L7,12.19H7s0,0,0,0L8.82,13.7a.69.69,0,0,0,.93,0l4.33-4.34h0l0,0,2.74,3.36Zm0-5.6-1.66-2-.1-.11a1.42,1.42,0,0,0-2,0L9.22,12.23,7.88,11.12a1.42,1.42,0,0,0-1.82,0L4.13,12.77V4.92a.79.79,0,0,1,.79-.79H16.08a.79.79,0,0,1,.79.79Z"
    />
  </svg>
)

const ImageFileIcon: React.FC<CustomIconComponentProps> = props => (
  <Icon component={ImageFileSvg} {...props} />
)
export default ImageFileIcon
