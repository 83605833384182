import React from "react"
import Icon from "@ant-design/icons"
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon"

const LinkSvg: React.FC = () => (
  <svg width="1em" height="1em" viewBox="0 0 21.014 21.015">
    <g transform="translate(0.994 1)">
      <g transform="translate(-0.993 -1)">
        <g transform="translate(0 0)">
          <path
            d="M7.718,185.7l-2.476,2.476a2.627,2.627,0,0,1-3.715-3.714l4.953-4.953a2.626,2.626,0,0,1,3.714,0,.876.876,0,0,0,1.238-1.238,4.377,4.377,0,0,0-6.191,0L.289,183.222a4.378,4.378,0,1,0,6.192,6.191l2.476-2.476A.876.876,0,1,0,7.718,185.7Z"
            transform="translate(0.993 -169.681)"
            fill="currentColor"
          />
          <path
            d="M201.831.282a4.378,4.378,0,0,0-6.192,0l-2.971,2.971a.876.876,0,0,0,1.238,1.238l2.971-2.971a2.627,2.627,0,1,1,3.715,3.714l-5.448,5.448a2.626,2.626,0,0,1-3.714,0,.876.876,0,0,0-1.238,1.238,4.377,4.377,0,0,0,6.191,0l5.448-5.448A4.377,4.377,0,0,0,201.831.282Z"
            transform="translate(-182.099 1)"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
)

const LinkIcon: React.FC<CustomIconComponentProps> = props => (
  <Icon component={LinkSvg} {...props} />
)
export default LinkIcon
