// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"
import {
  BAD_REQUEST,
  CANNOT_DOWNLOAD_FOLDER,
  INSUFFICIENT_PERMISSIONS,
  INVALID_FILE,
  INVALID_FOLDER_NAME,
  INVALID_ID_TOKEN,
  INVALID_PARENT_ID, LOAD_IMAGE_ERROR,
  LOGIN_REQUIRED,
  METHOD_NOT_ALLOWED,
  NOT_FOUND_APP,
  NOT_FOUND_FILE,
  NOT_IMPLEMENTED,
  PASSWORD_TOO_WEAK,
  TAG_ALREADY_EXISTS
} from "./src/constants/errors"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || "https://4dd91675aa1c44c48731a100c719ff69@o532738.ingest.sentry.io/6328707",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors: [
    LOGIN_REQUIRED,
    INVALID_ID_TOKEN,
    INSUFFICIENT_PERMISSIONS,
    CANNOT_DOWNLOAD_FOLDER,
    // BAD_REQUEST,
    // NOT_IMPLEMENTED,
    INVALID_PARENT_ID,
    INVALID_FOLDER_NAME,
    INVALID_FILE,
    // METHOD_NOT_ALLOWED,
    PASSWORD_TOO_WEAK,
    TAG_ALREADY_EXISTS,
    NOT_FOUND_FILE,
    NOT_FOUND_APP,
    LOAD_IMAGE_ERROR
  ]
})
