export enum Roles {
  Owner = "OWNER", //
  Admin = "ADMIN", // puo gestire tutto
  Editor = "EDITOR", // puo gestire tutti i file
  Author = "AUTHOR", // puo gestire i propri file
  Reader = "READER", // solo in lettura
}

export enum OrderBy {
  NameAsc = "+name",
  NameDesc = "-name",
  LastModifiedAsc = "+lastModified",
  LastModifiedDesc = "-lastModified",
  CreatedAtAsc = "+createdAt",
  CreatedAtDesc = "-createdAt",
}

export enum UserActions {
  // files
  CreateFile = "CREATE_FILE",
  UpdateFileMeta = "UPDATE_FILE_META",
  RenameFile = "RENAME_FILE",
  MoveFile = "MOVE_FILE",
  AddTagToFile = "ADD_TAG_TO_FILE",
  RemoveTagFromFile = "REMOVE_TAG_FROM_FILE",
  TrashFile = "TRASH_FILE",
  LikeFile = "LIKE_FILE",
  UnlikeFile = "UNLIKE_FILE",
  DownloadFile = "DOWNLOAD_FILE",
  UpdateFileDescription = "UPDATE_FILE_DESCRIPTION",
  DeleteFile = "DELETE_FILE",
  RestoreFile = "RESTORE_FILE",
  ChangeOwner = "CHANGE_OWNER",

  // file permissions
  ChangePermissionRole = "CHANGE_PERMISSION_ROLE",
  AddPermission = "ADD_PERMISSION",
  AddPermissions = "ADD_PERMISSIONS",
  RemovePermission = "REMOVE_PERMISSION",

  // organization
  CreateOrganization = "CREATE_ORGANIZATION",
  UpdateOrganizationInfo = "UPDATE_ORGANIZATION_INFO",
  AddTag = "ADD_TAG",
  RemoveTag = "REMOVE_TAG",
  RenameTag = "RENAME_TAG",
  UpdateTag = "UPDATE_TAG",

  // members
  InviteMember = "INVITE_MEMBER",
  RemoveMember = "REMOVE_MEMBER",
  ChangeMemberRole = "CHANGE_MEMBER_ROLE",

  // comments
  AddComment = "ADD_COMMENT",

  // apps
  InstallApp = "INSTALL_APP",
  UninstallApp = "UNINSTALL_APP",
}

export enum PermissionTypes {
  User = "USER",
  App = "APP",
  Url = "URL",
}

export enum Layouts {
  List = "list",
  Grid = "grid",
}

export enum FileStreamingStatus {
  Uploading = "UPLOADING",
  Cancelled = "CANCELLED",
  Paused = "PAUSED",
  UploadCompleted = "UPLOAD_COMPLETED",
  Failed = "FAILED",
  Downloading = "DOWNLOADING",
  DownloadCompleted = "DOWNLOAD_COMPLETED",
}

export enum DragAndDropTypes {
  File = "File",
}

export enum ThirdPartyAppPermissions {
  ReadFiles = "READ_FILES",
}
