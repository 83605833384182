import { IFile, IPermission } from "../interfaces/content-interfaces"
import React, { useMemo, useState } from "react"
import useFilePermissions from "./use-file-permissions"
import { uiHandleError } from "../utils"
import { Roles } from "../constants/enums"
import axios from "axios"
import { IPermissionInvitation } from "../interfaces/application-interfaces"
import { ShareFileModal } from "../components/share-file"

const useShareFile = () => {
  const [fileToShare, setFileToShare] = useState<IFile | null>(null)
  const { permissions, refetch, loading } = useFilePermissions(fileToShare?.id)
  const [processing, setProcessing] = useState(false)

  const shareFile = async (file: IFile) => {
    setFileToShare(file)
  }

  const handleRoleChange = async (permissionId: string, role: Roles) => {
    setProcessing(true)

    try {
      if (!fileToShare) {
        return
      }

      await axios.post(
        `/api/v1/files/${fileToShare.id}/permissions/${permissionId}`,
        {
          role: role,
        }
      )

      refetch()
    } catch (error: any) {
      uiHandleError(error)
    }
    setProcessing(false)
  }

  const handleRemovePermission = async (permissionId: string) => {
    try {
      setProcessing(true)

      if (!fileToShare) {
        return
      }

      await axios.delete(
        `/api/v1/files/${fileToShare.id}/permissions/${permissionId}`
      )

      refetch()
    } catch (error: any) {
      uiHandleError(error)
    }
    setProcessing(false)
  }

  const handleInvitation = async (invitation: {
    users: IPermissionInvitation[]
  }) => {
    let _error = null

    setProcessing(true)
    try {
      if (!fileToShare) {
        return
      }

      await axios.post(`/api/v1/files/${fileToShare.id}/permissions`, {
        users: invitation.users,
      })

      refetch()
    } catch (error: any) {
      _error = error
    }
    setProcessing(false)
    if (_error) {
      throw _error
    }
  }

  const handleUrlAccessToggle = async (permission: IPermission | null) => {
    setProcessing(true)
    try {
      if (fileToShare && permission) {
        // remove public access permission
        await axios.delete(
          `/api/v1/files/${fileToShare.id}/permissions/${permission.id}`
        )
      } else {
        // add public access permission
        await axios.post(`/api/v1/files/${fileToShare?.id}/permissions`, {
          urlAccess: true,
        })
      }
      refetch()
    } catch (e: any) {
      uiHandleError(e)
    }
    setProcessing(false)
  }

  const shareFileModal = useMemo(() => {
    return (
      <ShareFileModal
        file={fileToShare}
        loading={loading || processing}
        permissions={permissions}
        onClose={() => setFileToShare(null)}
        onUrlAccessToggle={handleUrlAccessToggle}
        onInvite={handleInvitation}
        onPermissionRemove={handleRemovePermission}
        onRoleChange={handleRoleChange}
      />
    )
  }, [permissions, fileToShare, loading, processing])

  return {
    shareFile,
    shareFileModal,
  }
}

export default useShareFile
