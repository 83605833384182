import React from "react"
import { Spin } from "antd"

import classNames from "./loading-view.module.scss"

export interface LoadingViewProps {}

const LoadingView: React.FC<LoadingViewProps> = () => {
  return (
    <div className={classNames.container}>
      <div className={classNames.content}>
        <Spin spinning size="large" />
      </div>
    </div>
  )
}

export default LoadingView
