import React, { ReactEventHandler, useRef, useState } from "react"
import { IViewerProps } from "../../interfaces/application-interfaces"
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch"
import { Button, Col, Spin, Row } from "antd"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { getFileIcon } from "../../utils"

//TODO: https://openbase.com/js/react-zoom-pan-pinch

interface ImageViewerProps extends IViewerProps {}

const ImageViewer: React.FC<ImageViewerProps> = ({ file }) => {
  const ref = useRef<ReactZoomPanPinchRef | null>(null)
  const [loading, setLoading] = useState(true)

  const handleImageLoad: ReactEventHandler<HTMLImageElement> = evt => {
    const image = evt.target as HTMLImageElement
    const xScale = window.innerWidth / image.width
    const yScale = window.innerHeight / image.height

    if (ref.current) {
      ref.current.centerView(Math.min(xScale, yScale))
    }

    setLoading(false)
  }

  return (
    <TransformWrapper ref={ref} centerOnInit={true} minScale={0.3}>
      <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
        <Spin spinning={loading}>
          <img
            onLoad={handleImageLoad}
            src={`/api/v1/files/${file.id}/preview`}
            alt={file.name}
          />
        </Spin>
      </TransformComponent>
    </TransformWrapper>
  )
}

export default ImageViewer
