import { JwtPayload } from "jsonwebtoken"
import { IPermission } from "../interfaces/content-interfaces"
import { UserProfile } from "@auth0/nextjs-auth0"
import { PermissionTypes, Roles } from "../constants/enums"
import { LOGIN_REQUIRED } from "../constants/errors"
import { createError } from "./index"

export const canReadFile = (
  filePermissions: IPermission[] | null,
  user: JwtPayload | UserProfile | undefined | null
): boolean => {
  if (!filePermissions) {
    return false
  }

  // public access permission check
  const publicAccessPermission = filePermissions.find(
    permission => permission.type === PermissionTypes.Url
  )

  if (publicAccessPermission) {
    return true
  }

  // user access permission check
  if (!user) {
    // return false
    // TODO: fix, throw error qui fa esplodere tutto
    throw createError(
      LOGIN_REQUIRED,
      401,
      "You must be logged in to access this resource"
    )
  }

  const appIdRegex = /.*\@clients/
  if (appIdRegex.test(user.sub || "")) {
    // application access permission check
    const appId = user.sub?.split("@")[0]
    const appPermission = filePermissions.find(
      permission => permission.clientId === appId
    )

    if (appPermission) {
      return true
    } else {
      return false
    }
  } else {
    const userPermission = filePermissions.find(
      permission => permission.user?.email === user.email
    )

    if (!userPermission) {
      return false
    }

    return [
      Roles.Admin,
      Roles.Editor,
      Roles.Owner,
      Roles.Reader,
      Roles.Author,
    ].includes(userPermission.role)
  }
}

export const canEditFile = (
  filePermissions: IPermission[] | null,
  user: JwtPayload | UserProfile | undefined | null
): boolean => {
  if (!filePermissions) {
    return false
  }

  if (!user) {
    return false
  }

  const userPermission = filePermissions.find(
    permission => permission.user?.email === user.email
  )

  if (!userPermission) {
    return false
  }

  return [Roles.Admin, Roles.Editor, Roles.Owner].includes(userPermission.role)
}

export const canManageTags = (
  filePermissions: IPermission[] | null,
  user: JwtPayload | UserProfile | undefined | null
): boolean => {
  if (!filePermissions) {
    return false
  }

  if (!user) {
    return false
  }

  const userPermission = filePermissions.find(
    permission => permission.user?.email === user.email
  )

  if (!userPermission) {
    return false
  }

  return (
    Boolean(userPermission.isMember) &&
    [Roles.Admin, Roles.Editor, Roles.Owner].includes(userPermission.role)
  )
}

export const canDeleteFile = (
  filePermissions: IPermission[] | null,
  user: JwtPayload | UserProfile | undefined | null
): boolean => {
  if (!filePermissions) {
    return false
  }

  if (!user) {
    return false
  }

  const userPermission = filePermissions.find(
    permission => permission.user?.email === user.email
  )

  if (!userPermission) {
    return false
  }

  return [Roles.Admin, Roles.Editor, Roles.Owner].includes(userPermission.role)
}

export const canCreateFile = (
  filePermissions: IPermission[] | null,
  user: JwtPayload | UserProfile | null | undefined
): boolean => {
  if (!filePermissions) {
    return false
  }

  if (!user) {
    return false
  }

  const userPermission = filePermissions.find(
    permission => permission.user?.email === user.email
  )

  if (!userPermission) {
    return false
  }

  return [Roles.Admin, Roles.Editor, Roles.Author, Roles.Owner].includes(
    userPermission.role
  )
}

export const canDownloadFile = (
  filePermissions: IPermission[] | null,
  user: JwtPayload | UserProfile | undefined | null
): boolean => {
  if (!filePermissions) {
    return false
  }

  if (!user) {
    return false
  }

  const appIdRegex = /.*\@clients/
  if (appIdRegex.test(user.sub || "")) {
    // application access permission check
    const appId = user.sub?.split("@")[0]
    const appPermission = filePermissions.find(
      permission => permission.clientId === appId
    )

    if (appPermission) {
      return true
    } else {
      return false
    }
  } else {
    const userPermission = filePermissions.find(
      permission => permission.user?.email === user.email
    )

    if (!userPermission) {
      return false
    }

    return [Roles.Admin, Roles.Editor, Roles.Owner].includes(
      userPermission.role
    )
  }
}

export const canCommentFile = (
  filePermissions: IPermission[] | null,
  user: JwtPayload | UserProfile | undefined | null
): boolean => {
  if (!filePermissions) {
    return false
  }

  if (!user) {
    return false
  }

  const userPermission = filePermissions.find(
    permission => permission.user?.email === user.email
  )

  if (!userPermission) {
    return false
  }

  return [Roles.Admin, Roles.Editor, Roles.Author, Roles.Owner].includes(
    userPermission.role
  )
}

export const canShareFile = (
  filePermissions: IPermission[] | null,
  user: JwtPayload | UserProfile | undefined | null
): boolean => {
  if (!filePermissions) {
    return false
  }

  if (!user) {
    return false
  }

  const userPermission = filePermissions.find(
    permission => permission.user?.email === user.email
  )

  if (!userPermission) {
    return false
  }

  return [Roles.Admin, Roles.Editor, Roles.Owner].includes(userPermission.role)
}
